import { DashboardData } from "../queries/models/dashboard/dashboard-data.model";
import { BaseService } from "./base.service";

class DashboardService extends BaseService {
  async getData(communityId: number): Promise<DashboardData> {
    const url = `${this.endpointPath}/${communityId}`;

    return this.http.get<DashboardData>(url).then((res) => res.data);
  }
}

export const dashboardService = new DashboardService("data-access/dashboard");
