import { useMemo } from "react";
import { DataLoader } from "../ui/DataLoader";
import { UseQueryResult } from "react-query";
import { TimeChart, TimeChartData } from "../ui/TimeChart";

interface ChartDataPoint {
  date: Date;
  value: number;
}

interface CoinsHistoryProps {
  data?: ChartDataPoint[];
  query?: UseQueryResult;
}

export function CoinsHistory({ data, query }: CoinsHistoryProps) {
  const chartData = useMemo(() => {
    if (data) {
      const points = data.map((x) => ({
        date: x.date,
        value: x.value,
      }));

      return [
        {
          name: "Coins change",
          unit: "",
          type: "bar",
          points: points,
        },
      ] as TimeChartData[];
    } else {
      return [];
    }
  }, [data]);

  return (
    <DataLoader
      query={query}
      height="300px"
      style={{ height: 300, minWidth: 400 }}
      className="grid"
    >
      {data?.length ? (
        <TimeChart data={chartData} />
      ) : (
        <div className="justify-self-center self-center">No history</div>
      )}
    </DataLoader>
  );
}
