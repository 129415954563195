import React from "react";
import { EnergyCommunityContextProvider } from "./components/ui/EnergyCommunityContext";
import { TopBarNavigation } from "./components/navigation/top-bar-navigation";
import { SideBarNavigation } from "./components/navigation/side-bar-nav";
import classNames from "classnames";
import { Navigate, Route, Routes } from "react-router-dom";
import { Communities } from "./pages/Communities";
import { CommunityDetails } from "./pages/CommunityDetails";
import { Dashboard } from "./pages/Dashboard";
import { EvCharging } from "./pages/EvCharging";
import { UserDetails } from "./pages/UserDetails";
import { Users } from "./pages/Users";
import { Wallet } from "./pages/Wallet";
import { useAtom } from "jotai";
import { sideBarAtom } from "./atoms/side-bar-atom";
import { useAuth } from "./auth/AuthProvider";
import { Settings } from "./pages/Settings";
import { Translations } from "./pages/Translations";
import { AxiosInterceptor } from "./components/ui/AxiosInterceptorsWrapper";
import { CommunityAdministration } from "./pages/CommunityAdministration";
import { CommunityMemberDetails } from "./pages/CommunityMemberDetails";
import { SalesDocumentsAdministration } from "./pages/SalesDocumentsAdministration";

export function MainApp() {
  const [sidebarState] = useAtom(sideBarAtom);

  const auth = useAuth();

  if (!auth.userData?.accessToken) {
    return <Navigate to="login" />;
  }

  return (
    <AxiosInterceptor>
      <EnergyCommunityContextProvider>
        <TopBarNavigation></TopBarNavigation>
        <SideBarNavigation></SideBarNavigation>
        <div className="pt-14 justify-content-end flex">
          <div
            className={classNames(
              "w-full transition-duration-150",
              sidebarState.expanded
                ? "lg:pl-56 pl-0"
                : "w-full lg:pl-20 pr-0 lg:pr-1 pl-0 lg:pb-0 pb-12"
            )}
          >
            <Routes>
              <Route
                path="/"
                element={<Navigate to="dashboard" />}
              />
              <Route
                path="dashboard"
                element={<Dashboard />}
              />
              <Route
                path="communities"
                element={<Communities />}
              />
              <Route
                path="communities/:id"
                element={<CommunityDetails />}
              />
              <Route
                path="members/:id"
                element={<CommunityMemberDetails />}
              />
              <Route
                path="community-administration"
                element={<CommunityAdministration />}
              />
              <Route
                path="users"
                element={<Users />}
              />
              <Route
                path="users/:id"
                element={<UserDetails />}
              />
              <Route
                path="wallet"
                element={<Wallet />}
              />
              <Route
                path="ev-charging"
                element={<EvCharging />}
              />
              <Route
                path="translations"
                element={<Translations />}
              />
              <Route
                path="settings"
                element={<Settings />}
              />
              <Route
                path="sales-documents-administration"
                element={<SalesDocumentsAdministration />}
              />
            </Routes>
          </div>
        </div>
      </EnergyCommunityContextProvider>
    </AxiosInterceptor>
  );
}
