import { createContext, useContext, useEffect, useState } from "react";
import { CenteredLoader } from "./CenteredLoader";
import { NoCommunitiesInfo } from "./NoCommunitiesInfo";
import { useCommunitiesForSelectorQuery } from "../../queries/communities/communities.query";

export interface EnergyCommunityContextProviderProps {
  children: React.ReactNode;
}

export interface EnergyCommunityContextProps {
  selectedCommunityId: number;
  setSelectedCommunityId: (v: number) => void;
}

const EnergyCommunityContext = createContext<EnergyCommunityContextProps>({
  selectedCommunityId: 0,
  setSelectedCommunityId: (v) => {},
});

export function EnergyCommunityContextProvider({
  children,
}: EnergyCommunityContextProviderProps) {
  const energyCommunitiesQuery = useCommunitiesForSelectorQuery();
  const [selectedCommunityId, setSelectedCommunityId] = useState<number>(0);

  useEffect(() => {
    if (energyCommunitiesQuery.data) {
      if (
        !selectedCommunityId ||
        !energyCommunitiesQuery.data.find((d) => d.id === selectedCommunityId)
      ) {
        setSelectedCommunityId(energyCommunitiesQuery.data[0].id);
      }
    }
  }, [
    energyCommunitiesQuery.data,
    selectedCommunityId,
    setSelectedCommunityId,
  ]);

  if (!energyCommunitiesQuery.data && energyCommunitiesQuery.isLoading) {
    return <CenteredLoader />;
  }

  if (!energyCommunitiesQuery.data) return <></>;

  if (energyCommunitiesQuery.data!.length === 0) {
    return <NoCommunitiesInfo />;
  }

  return (
    <EnergyCommunityContext.Provider
      value={{
        selectedCommunityId: selectedCommunityId,
        setSelectedCommunityId: setSelectedCommunityId,
      }}
    >
      {children}
    </EnergyCommunityContext.Provider>
  );
}

export function useEnergyCommunitySelector() {
  return useContext(EnergyCommunityContext);
}
