import { formatNumber } from "../../utils/number-utils";
import { DataLoader } from "../ui/DataLoader";
import { UseQueryResult } from "react-query";

interface CoinsBalanceProps {
  coins?: number;
  eur?: number;
  query?: UseQueryResult;
}

export function CoinsBalance({ coins, eur, query }: CoinsBalanceProps) {
  return (
    <div className="flex flex-col items-center justify-center">
      <div>
        <img
          className="w-24 p-1"
          src="/images/total_savings.png"
          alt="img"
        />
      </div>
      <DataLoader
        query={query}
        height="1.5rem"
        width="70%"
        loaderClassName="mt-2"
        className="text-3xl font-bold text-center text-slate-700"
      >
        {formatNumber(coins, 2, 2)}
      </DataLoader>
      <DataLoader
        loaderClassName="mt-2"
        query={query}
      >
        ({formatNumber(eur, 2, 2)} €)
      </DataLoader>
    </div>
  );
}
