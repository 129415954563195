import { ChargingSession } from "../queries/models/ev/charging-session";
import { BaseService } from "./base.service";

class ChargingSessionsService extends BaseService {
  async getChargingSessions(
    communityId: number,
    from: Date,
    to: Date
  ): Promise<ChargingSession[]> {
    const url = `${this.endpointPath}/${communityId}/member-charging-sessions`;

    const params = {
      from: from.toISOString(),
      to: to.toISOString(),
    };

    return this.http
      .get<ChargingSession[]>(url, { params })
      .then((res) => res.data);
  }
}

export const chargingSessionsService = new ChargingSessionsService(
  "data-access/ev"
);
