import * as Yup from "yup";
import { useCallback } from "react";
import { Form, Formik } from "formik";
import { FormToolbar } from "../ui/form-toolbar";
import { useToast } from "../ui/ToastContext";
import FormikInputText from "../formik/FormikInputText";
import { CreateUpdateCommunityMember } from "../../queries/models/community-administration/create-update-community-member.model";
import FormikInputNumber from "../formik/FormikInputNumber";

interface CreateMemberFormProps {
  onSave: (model: CreateUpdateCommunityMember) => void;
  onCancel: () => void;
  communityId: number;
}

export function CreateMemberForm({
  onSave,
  onCancel,
  communityId,
}: CreateMemberFormProps) {
  const initialValues: CreateUpdateCommunityMember = {
    email: "",
    investment: 0,
    communityId: communityId,
  };
  const toast = useToast();
  const validationSchema = Yup.object({
    email: Yup.string()
      .min(3, "Must be 3 characters or more")
      .required("Required"),
    investment: Yup.number().min(1).required(),
  });

  const onSubmit = useCallback(
    (values: CreateUpdateCommunityMember) => {
      return onSave(values);
    },
    [onSave]
  );

  return (
    <div className="h-full w-full overflow-auto min-w-80">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
        validateOnMount
      >
        {(formik) => (
          <Form className="flex h-full flex-col">
            <div className="formgrid grid mb-4 p-1">
              <div className="field col-12 md:col-6">
                <FormikInputText
                  validationSchema={validationSchema}
                  label="Email"
                  name="email"
                  isIndependent
                  disabled={false}
                />
              </div>
            </div>
            <div className="formgrid grid mb-4 p-1">
              <div className="field col-12 md:col-6">
                <FormikInputNumber
                  validationSchema={validationSchema}
                  label="Investment [€]"
                  name="investment"
                  isIndependent
                  disabled={false}
                />
              </div>
            </div>

            <FormToolbar
              showDeleteButton={false}
              saveButtonDisabled={!formik.dirty || !formik.isValid}
              onSaveClick={async () => {
                if (!formik.isValid) {
                  toast.current?.show({
                    severity: "error",
                    detail: "Form invalid",
                  });
                  return;
                }
                return formik.submitForm();
              }}
              onCancelClick={onCancel}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
