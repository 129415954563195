import { CreateUpdateCommunityMember } from "../queries/models/community-administration/create-update-community-member.model";
import { Member } from "../queries/models/community-administration/member.model";
import { BaseService } from "./base.service";

class MemberAdministrationService extends BaseService {
  async getCommunityMembers(communityId: number) {
    const url = `${this.endpointPath}/community/${communityId}`;
    return this.http.get<Member[]>(url).then((res) => res.data);
  }

  async getMember(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.get(url).then((res) => res.data);
  }
  async createMember(model: CreateUpdateCommunityMember) {
    const url = `${this.endpointPath}`;
    return this.http.post(url, model).then((res) => res.data);
  }
  async updateMember(id: number, model: CreateUpdateCommunityMember) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.put(url, model).then((res) => res.data);
  }
  async deleteMember(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.delete(url).then((res) => res.data);
  }
}

export const memberConfigurationService = new MemberAdministrationService(
  "administration/community-member-configuration"
);
