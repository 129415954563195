import { format } from "date-fns";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useMemo, useState } from "react";
import { useCoinTransactionsQuery } from "../../queries/wallet/wallet.query";
import {
  CoinTransaction,
  CoinTransactionType,
} from "../../queries/models/wallet/coin-transaction.model";
import { formatNumber, roundToDecimals } from "../../utils/number-utils";
import { CoinsHistory } from "../dashboard/CoinsHistory";
import { CoinTransactionsDetailsDialog } from "./CoinTransactionsDetailsDialog";
import { coinTransactionTypesNames } from "../../utils/enum-utils";

interface CoinsTransactionsProps {
  communityId: number;
}

export function CoinsTransactions({ communityId }: CoinsTransactionsProps) {
  const query = useCoinTransactionsQuery(communityId);
  const [selectedTransaction, setSelectedTransaction] =
    useState<CoinTransaction | null>(null);

  const data = query.data;

  const chartData = useMemo(() => {
    if (!data) {
      return [];
    }

    const grouped = data.reduce((acc, x) => {
      const key = format(x.date, "yyyy-MM");
      if (!acc[key]) {
        acc[key] = 0;
      }

      const sign =
        x.type === CoinTransactionType.ConsumptionFromMember ? -1 : 1;

      acc[key] += sign * x.coins;
      return acc;
    }, {} as { [key: string]: number });

    const points = Object.keys(grouped).map((key) => ({
      date: new Date(key),
      value: roundToDecimals(grouped[key], 2),
    }));

    return points;
  }, [data]);

  function coinTransactionRow(rowData: CoinTransaction) {
    function formattedValue(transaction: CoinTransaction) {
      let value = transaction.coins;
      if (
        transaction.type === CoinTransactionType.ConsumptionFromMember &&
        transaction.coins > 0
      ) {
        value = -value;
      }
      return (value > 0 ? "+" : "") + formatNumber(value, 2, 2);
    }

    return (
      <div>
        <div className="text-right">{formattedValue(rowData)}</div>
      </div>
    );
  }

  function rowClicked(rowData: CoinTransaction) {
    setSelectedTransaction(rowData);
  }

  return (
    <div>
      <div>
        <CoinsHistory
          query={query}
          data={chartData}
        />
      </div>

      <DataTable
        value={data}
        onRowClick={(rowData) => rowClicked(rowData.data as CoinTransaction)}
        rowClassName={(rowData) => "cursor-pointer"}
      >
        <Column
          header="Date"
          body={(rowData) => {
            return <div>{new Date(rowData.date).toLocaleDateString()}</div>;
          }}
        />
        <Column
          field="type"
          header="Transaction type"
          body={(rowData: CoinTransaction) => (
            <span>{coinTransactionTypesNames[rowData.type]}</span>
          )}
        />
        <Column
          field="coins"
          header="Coins"
          body={(rowData: CoinTransaction) => coinTransactionRow(rowData)}
        />
      </DataTable>
      <CoinTransactionsDetailsDialog
        coinTransaction={selectedTransaction}
        onHide={() => setSelectedTransaction(null)}
      />
    </div>
  );
}
