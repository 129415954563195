import { memo, useMemo, useState } from "react";
import { useCommunitySalesDocumentsQuery } from "../../queries/sales-documents/sales-documents.query";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  SalesDocument,
  SalesDocumentType,
  salesDocumentTypeOptions,
} from "../../queries/models/sales-documents/sales-document.model";
import { addMonths, formatDate, startOfMonth } from "date-fns";
import { SalesDocumentFormDialog } from "./SalesDocumentFormDialog";
import { Button } from "primereact/button";
import { useEnergyCommunitySelector } from "../ui/EnergyCommunityContext";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import Enumerable from "linq";
import { Calendar } from "primereact/calendar";
import { useCoinsProcessingMutation } from "../../queries/coins-processing/coins-processing.query";
import { useToast } from "../ui/ToastContext";

function formattedDateSelector<
  T,
  R extends Date | string | number | null | undefined =
    | Date
    | string
    | number
    | null
    | undefined
>(f: (x: T) => R): (v: T) => string {
  return (x) => {
    const selected = f(x);
    if (!selected) return "";
    return formatDate(selected, "dd.MM.yyyy HH:mm");
  };
}

export const SalesDocumentsDetails = memo(() => {
  const { selectedCommunityId } = useEnergyCommunitySelector();

  const [selectedMonth, setSelectedMonth] = useState<Date>(
    addMonths(new Date(), -1)
  );
  const selectedDateRange = useMemo(
    () => [
      startOfMonth(selectedMonth),
      addMonths(startOfMonth(selectedMonth), 1),
    ],
    [selectedMonth]
  );

  const coinsProcessingMutation = useCoinsProcessingMutation();

  const communitySalesDocumentsQuery = useCommunitySalesDocumentsQuery(
    selectedCommunityId,
    selectedDateRange[0],
    selectedDateRange[1]
  );

  const maxProcessedFinishDate = useMemo(() => {
    if (!communitySalesDocumentsQuery.data) return;

    return Enumerable.from(communitySalesDocumentsQuery.data)
      .where((x) => x.isProcessed)
      .orderByDescending((x) => x.to)
      .firstOrDefault()?.to;
  }, [communitySalesDocumentsQuery.data]);

  const unprocessedDocuments = useMemo(() => {
    if (!communitySalesDocumentsQuery.data) return 0;

    return Enumerable.from(communitySalesDocumentsQuery.data).count(
      (x) => !x.isProcessed
    );
  }, [communitySalesDocumentsQuery.data]);

  const [selectedSalesDocument, setSelectedSalesDocument] =
    useState<Partial<SalesDocument>>();

  const toast = useToast();

  return (
    <div>
      <div>
        <Calendar
          view="month"
          dateFormat="mm.yy"
          value={selectedMonth}
          onChange={(e) => e.value && setSelectedMonth(e.value)}
        />
        <Button
          label={`Process all new documents (${unprocessedDocuments})`}
          onClick={() => {
            coinsProcessingMutation
              .mutateAsync({
                communityId: selectedCommunityId,
                from: selectedDateRange[0],
                to: selectedDateRange[1],
              })
              .then(
                () => {
                  toast.current?.show({
                    content: "Processed successfully",
                    severity: "success",
                  });
                },
                () => {
                  toast.current?.show({
                    content: "Processing failed",
                    severity: "error",
                  });
                }
              );
          }}
          severity="info"
          disabled={coinsProcessingMutation.isLoading}
          loading={coinsProcessingMutation.isLoading}
        />
      </div>
      <div className="mt-4">
        <Button
          label="Create Sales document"
          onClick={() => setSelectedSalesDocument({})}
        />
      </div>

      <DataTable
        value={communitySalesDocumentsQuery.data}
        rowHover
        virtualScrollerOptions={{
          autoSize: true,
          itemSize: 81,
        }}
        scrollable
        scrollHeight="700px"
        rowClassName={(data) =>
          `cursor-pointer ${
            maxProcessedFinishDate && data.to <= maxProcessedFinishDate
              ? data.isProcessed
                ? "!bg-green-100"
                : "!bg-red-100"
              : ""
          }`
        }
        onRowClick={(e) => setSelectedSalesDocument(e.data as SalesDocument)}
      >
        <Column
          field="id"
          header="ID"
          filter
          filterType="number"
        />
        <Column
          field="type"
          header="Type"
          body={(x: SalesDocument) => SalesDocumentType[x.type]}
          filter
          showFilterMenuOptions={false}
          filterMatchMode={FilterMatchMode.CUSTOM}
          filterFunction={(v, f) => !f.length || f.includes(v)}
          filterElement={(o) => (
            <MultiSelect
              value={o.value}
              options={salesDocumentTypeOptions}
              onChange={(e) => o.filterApplyCallback(e.value)}
              placeholder="Select types"
            />
          )}
          filterApply={<></>}
        />
        <Column
          field="from"
          header="From"
          body={formattedDateSelector<SalesDocument>((x) => x.from)}
        />
        <Column
          field="to"
          header="To"
          body={formattedDateSelector<SalesDocument>((x) => x.to)}
        />
        <Column
          field="amountEur"
          header="Eur"
          filter
          filterType="number"
        />
        <Column
          field="amountKwh"
          header="Kwh"
          filter
          filterType="number"
        />
        <Column
          field="meterEan"
          header="EAN"
          filter
        />
        <Column
          field="processedAt"
          header="Processed"
          body={formattedDateSelector<SalesDocument>((x) => x.processedAt)}
          filter
          showFilterMenuOptions={false}
          filterMatchMode={FilterMatchMode.CUSTOM}
          filterFunction={(v, f) => f === null || !!f === !!v}
          filterElement={(o) => (
            <>
              <TriStateCheckbox
                value={o.value}
                onChange={(e) => o.filterApplyCallback(e.value)}
                className="mr-2"
              />
              {["Not processed", "Processed", "Any"][+(o.value ?? 2)]}
            </>
          )}
          filterApply={<></>}
        />
        <Column
          field="number"
          header="Number"
          filter
        />
      </DataTable>

      <SalesDocumentFormDialog
        salesDocument={selectedSalesDocument}
        onHide={() => setSelectedSalesDocument(undefined)}
      />
    </div>
  );
});
