import {
  CoinTransaction,
  CoinTransactionType,
} from "../queries/models/wallet/coin-transaction.model";
import { CoinTransactionsDetails } from "../queries/models/wallet/coin-transactions-details";
import { CoinsBalance } from "../queries/models/wallet/coins-balance.model";
import { BaseService } from "./base.service";

class WalletService extends BaseService {
  async getCoinsBalance(communityId: number): Promise<CoinsBalance> {
    const url = `${this.endpointPath}/${communityId}/balance`;

    return this.http.get<CoinsBalance>(url).then((res) => res.data);
  }

  async getCoinTransactions(communityId: number): Promise<CoinTransaction[]> {
    const url = `${this.endpointPath}/${communityId}/history`;

    return this.http.get<CoinTransaction[]>(url).then((res) => res.data);
  }

  async getCoinTransactionsDetails(
    communityId: number,
    date: Date,
    type: CoinTransactionType
  ): Promise<CoinTransactionsDetails[]> {
    const url = `${
      this.endpointPath
    }/${communityId}/details?date=${date.toISOString()}&type=${type}`;

    return this.http
      .get<CoinTransactionsDetails[]>(url)
      .then((res) => res.data);
  }
}

export const walletService = new WalletService("data-access/wallet");
