import { useMutation, useQuery } from "react-query";
import { usersService } from "../../services/users.service";
import { EditUser } from "./edit-user";

export function useUsersQuery() {
  return useQuery("users", () => usersService.getUsers());
}

export function useUserQuery(id: number) {
  return useQuery(["user", id], () => usersService.getUser(id));
}

export function useAddUserMutation() {
  return useMutation((user: EditUser) => usersService.addUser(user));
}

export function useEditUserMutation() {
  return useMutation((data: { id: number; user: EditUser }) =>
    usersService.editUser(data.id, data.user)
  );
}
