import { InputText, InputTextProps } from "primereact/inputtext";

interface InputWithLabelProps extends InputTextProps {
  label: string;
}

export function InputWithLabel({ label, ...rest }: InputWithLabelProps) {
  return (
    <>
      <div className="">
        <label className="font-semibold text-md mb-1 py-2 block text-gray-500">
          {label}
        </label>
        <InputText
          {...rest}
          className="shadow-sm"
        />
      </div>
    </>
  );
}
