import { Skeleton, SkeletonProps } from "primereact/skeleton";
import { CSSProperties } from "react";
import { UseQueryResult } from "react-query";

export type DataLoaderProps = SkeletonProps &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    query?: UseQueryResult;
    loaderStyle?: CSSProperties;
    loaderClassName?: string;
  };

export function DataLoader({
  query,
  loaderStyle,
  loaderClassName,
  children,
  ...props
}: DataLoaderProps) {
  if (query && (query.isLoading || query.isError)) {
    return (
      <Skeleton
        borderRadius="1em"
        animation={query.isFetching ? "wave" : "none"}
        {...(props as any)}
        pt={
          query.isError
            ? {
                root: {
                  style: {
                    transition: "0.5s",
                    backgroundColor: "#80262644",
                  },
                },
              }
            : undefined
        }
        className={loaderClassName}
        style={loaderStyle}
      />
    );
  } else {
    return <div {...props}>{children}</div>;
  }
}
