import { DataTable } from "primereact/datatable";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { Column } from "primereact/column";
import { BackButton } from "../components/ui/BackButton";
import { RoundedCard } from "../components/ui/RoundedCard";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useQueryClient } from "react-query";
import { useToast } from "../components/ui/ToastContext";
import {
  useDeleteMemberMutation,
  useMemberQuery,
  useUpdateMemberMutation,
} from "../queries/member-administration/member-administration.query";
import * as Yup from "yup";
import { CreateUpdateCommunityMember } from "../queries/models/community-administration/create-update-community-member.model";
import { Formik, Form } from "formik";
import FormikInputNumber from "../components/formik/FormikInputNumber";
import FormikInputText from "../components/formik/FormikInputText";
import { Member } from "../queries/models/communities/member.model";
import { EnergyMeter } from "../queries/models/community-administration/energy-meter.model";
import { EnergyMeterDialog } from "../components/energy-meters/EnergyMeterDialog";

export function CommunityMemberDetails() {
  const { id } = useParams();
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [energyMeterSubject, setEnergyMeterSubject] = useState<
    EnergyMeter | undefined
  >();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();

  const memberQuery = useMemberQuery(Number(id));
  const deleteMemberMutation = useDeleteMemberMutation();
  const updateMemberMutation = useUpdateMemberMutation();

  const validationSchema = Yup.object({
    coins: Yup.number().min(0).optional(),
    share: Yup.number().min(0).optional(),
    name: Yup.string()
      .min(3, "Must be 3 characters or more")
      .required("Required"),
    investment: Yup.number().min(1).required(),
  });

  const handleShowCreateEnergyMeterDialog = useCallback(() => {
    setEnergyMeterSubject(undefined);
    setDialogVisible(true);
  }, []);

  const handleShowEditEnergyMeterDialog = useCallback(
    (subject: EnergyMeter) => {
      setEnergyMeterSubject(subject);
      setDialogVisible(true);
    },
    []
  );

  const initialValues: Member = {
    id: memberQuery.data?.id ?? 0,
    name: memberQuery.data?.name ?? "",
    investment: memberQuery.data?.investment ?? 0,
    share: memberQuery.data?.share ?? 0,
    coins: memberQuery.data?.coins ?? 0,
    communityId: memberQuery.data?.communityId ?? 0,
    dateCreated: memberQuery.data?.dateCreated ?? new Date(),
    userId: memberQuery.data?.userId ?? 0,
    user: memberQuery.data?.user ?? undefined,
    meters: memberQuery.data?.meters ?? 0,
    energyMeters: memberQuery.data?.energyMeters ?? [],
  };

  const onSave = useCallback(
    (model: CreateUpdateCommunityMember) => {
      if (model) {
        const mutateOptions = {
          onSuccess: async () => {
            await queryClient.invalidateQueries("members");
            toast.current?.show({
              severity: "success",
              detail: "Community member updated",
            });
          },
          onError: async (error: any) => {
            toast.current?.show({
              severity: "error",
              detail: error.response?.data ?? error.message,
            });
          },
        };

        updateMemberMutation.mutateAsync(model, mutateOptions);
      }
    },
    [queryClient, toast, updateMemberMutation]
  );

  const onSubmit = useCallback(
    (values: Member) => {
      return onSave({
        id: values.id,
        communityId: values.communityId,
        email: values.name,
        investment: values.investment,
      });
    },
    [onSave]
  );

  const energyMeters = useMemo(() => {
    if (memberQuery.data) {
      return memberQuery.data.energyMeters;
    }
  }, [memberQuery.data]);

  const navigateBack = useCallback(() => {
    navigate("/communities/" + memberQuery.data?.communityId);
  }, [navigate, memberQuery.data?.communityId]);

  const handleDeleteMember = useCallback(() => {
    confirmDialog({
      message: "Are you sure you want permanently archive this member?",
      header: "Confirmation",
      icon: "pi pi-question-circle text-green-600",
      accept: () => {
        const mutateOptions = {
          onSuccess: async () => {
            await queryClient.invalidateQueries("communities");
            navigateBack();
            toast.current?.show({
              severity: "success",
              detail: "Community archived!",
            });
          },
          onError: async (error: any) => {
            toast.current?.show({
              severity: "error",
              detail: error.response?.data ?? error.message,
            });
          },
        };
        deleteMemberMutation.mutateAsync(Number(id), mutateOptions);
      },
    });
  }, [deleteMemberMutation, id, navigateBack, queryClient, toast]);

  return (
    <RoundedCard>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
        validateOnMount
      >
        {(formik) => (
          <Form className="flex h-full flex-col">
            <div className="flex flex-row justify-between">
              <BackButton onClick={() => navigateBack()} />

              <div className="flex flex-row gap-1 mt-2">
                <Button
                  label="Add energy meter"
                  icon="pi pi-plus"
                  type="button"
                  className="p-button-success"
                  onClick={handleShowCreateEnergyMeterDialog}
                />
              </div>
              <div className="flex flex-row gap-1 mt-2">
                <Button
                  icon="pi pi-save"
                  type="submit"
                  label="Save"
                  className="p-button-success"
                  disabled={!formik.dirty || !formik.isValid}
                />
                <Button
                  label="Delete member"
                  icon="pi pi-trash"
                  className="p-button-danger"
                  onClick={handleDeleteMember}
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="formgrid grid mb-4 p-1">
                <div className="field col-12 md:col-6">
                  <FormikInputText
                    validationSchema={validationSchema}
                    label="Name"
                    name="name"
                    isIndependent
                    disabled={false}
                  />
                </div>
              </div>
              <div className="formgrid grid mb-4 p-1">
                <div className="field col-12 md:col-6">
                  <FormikInputText
                    validationSchema={validationSchema}
                    label="Coins"
                    name="coins"
                    isIndependent
                    disabled={true}
                  />
                </div>
              </div>
              <div className="formgrid grid mb-4 p-1">
                <div className="field col-12 md:col-6">
                  <FormikInputText
                    validationSchema={validationSchema}
                    label="Share"
                    name="share"
                    isIndependent
                    disabled={true}
                  />
                </div>
              </div>
              <div className="formgrid grid mb-4 p-1">
                <div className="field col-12 md:col-6">
                  <FormikInputNumber
                    validationSchema={validationSchema}
                    label="Investment [€]"
                    name="investment"
                    isIndependent
                    disabled={false}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <DataTable value={energyMeters}>
        <Column
          field="ean"
          header="EAN"
        />
        <Column
          field="name"
          header="Name"
        />
        <Column
          field="isProducer"
          header="Is producer"
          body={(x) => (x.isProducer ? "Yes" : "No")}
        />
        <Column
          field="isProductionCommunityOwned"
          header="Production public"
          body={(x) => (x.isProductionCommunityOwned ? "Yes" : "No")}
        />
        <Column
          field="isConsumer"
          header="Is consumer"
          body={(x) => (x.isConsumer ? "Yes" : "No")}
        />

        <Column
          header=""
          style={{ width: "6rem" }}
          body={(rowData) => (
            <div className="flex flex-row gap-1">
              <Button
                label="Edit"
                icon="pi pi-info-circle"
                className="p-button-warning"
                onClick={() => {
                  handleShowEditEnergyMeterDialog(rowData);
                }}
              />
            </div>
          )}
        />
      </DataTable>
      <ConfirmDialog />

      <EnergyMeterDialog
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        subject={energyMeterSubject}
        member={memberQuery.data}
      ></EnergyMeterDialog>
    </RoundedCard>
  );
}
