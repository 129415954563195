import { Dialog } from "primereact/dialog";
import {
  CoinTransaction,
  CoinTransactionType,
} from "../../queries/models/wallet/coin-transaction.model";
import { useCoinTransactionsDetailsQuery } from "../../queries/wallet/wallet.query";
import { useEnergyCommunitySelector } from "../ui/EnergyCommunityContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { coinTransactionTypesNames } from "../../utils/enum-utils";

interface CoinTransactionsDetailsDialogProps {
  coinTransaction: CoinTransaction | null;
  onHide: () => void;
}

export function CoinTransactionsDetailsDialog({
  coinTransaction,
  onHide,
}: CoinTransactionsDetailsDialogProps) {
  const { selectedCommunityId } = useEnergyCommunitySelector();
  const { data } = useCoinTransactionsDetailsQuery(
    selectedCommunityId,
    coinTransaction?.date,
    coinTransaction?.type
  );

  return (
    <Dialog
      visible={!!coinTransaction}
      onHide={onHide}
      className="min-w-[600px]"
    >
      <DataTable value={data}>
        <Column
          body={(rowData) => rowData.date.toLocaleDateString()}
          header="Date"
        />
        <Column
          header="Transaction type"
          body={(rowData) =>
            coinTransactionTypesNames[rowData.type as CoinTransactionType]
          }
        />
        <Column
          header="Meter"
          body={(rowData) => rowData.meter ?? "-"}
        />
        <Column
          header="From"
          body={(rowData) => rowData.fromMember ?? "-"}
        />
        <Column
          header="To"
          body={(rowData) => rowData.toMember ?? "-"}
        />
        <Column
          field="amount"
          header="Amount [€]"
        />
      </DataTable>
    </Dialog>
  );
}
