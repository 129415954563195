import { User } from "../queries/models/users/user.model";
import { EditUser } from "../queries/users/edit-user";
import { BaseService } from "./base.service";

class UsersService extends BaseService {
  async getUsers(): Promise<User[]> {
    const url = `${this.endpointPath}/active`;
    return this.http.get<User[]>(url).then((res) => res.data);
  }

  async getUser(id: number): Promise<User> {
    const url = `${this.endpointPath}/${id}`;

    return this.http.get<User>(url).then((res) => res.data);
  }

  async addUser(user: EditUser): Promise<User> {
    const url = `${this.endpointPath}`;
    return this.http.post<User>(url, user).then((res) => res.data);
  }

  async editUser(id: number, user: EditUser): Promise<User> {
    const url = `${this.endpointPath}/${id}`;
    return this.http.put<User>(url, user).then((res) => res.data);
  }
}

export const usersService = new UsersService("administration/users");
