import { DataTable } from "primereact/datatable";
import { ContentWithLabel } from "../ui/ContentWithLabel";
import { Column } from "primereact/column";
import { useCommunityQuery } from "../../queries/community-administration/community-administration.query";
import { useMemo } from "react";

interface CommunityDetailsProps {
  communityId: number;
}

export function CommunityDetails({ communityId }: CommunityDetailsProps) {
  const communityQuery = useCommunityQuery(communityId);

  const members = useMemo(() => {
    if (communityQuery.data) {
      return communityQuery.data.members;
    }
  }, [communityQuery.data]);

  return (
    <div>
      <ContentWithLabel label="Name">
        {communityQuery.data?.name}
      </ContentWithLabel>

      <h2>Members</h2>
      <DataTable value={members}>
        <Column
          field="id"
          header="ID"
        />
        <Column
          field="name"
          header="Name"
        />
        <Column
          field="share"
          header="Share"
        />
        <Column
          field="coins"
          header="Energy coins"
        />
      </DataTable>
    </div>
  );
}
