import { useQuery } from "react-query";
import { walletService } from "../../services/wallet.service";
import { CoinTransactionType } from "../models/wallet/coin-transaction.model";

export function useCoinsBalanceQuery(communityId: number) {
  return useQuery(
    ["coinsBalance", communityId],
    () => walletService.getCoinsBalance(communityId),
    {
      enabled: !!communityId,
    }
  );
}

export function useCoinTransactionsQuery(communityId: number) {
  return useQuery(
    ["coinTransactions", communityId],
    () => walletService.getCoinTransactions(communityId),
    {
      enabled: !!communityId,
    }
  );
}

export function useCoinTransactionsDetailsQuery(
  communityId: number,
  date?: Date,
  type?: CoinTransactionType
) {
  return useQuery(
    ["coinTransactionsDetails", date, type],
    () => walletService.getCoinTransactionsDetails(communityId, date!, type!),
    {
      enabled: !!date && !!type && !!communityId,
    }
  );
}
