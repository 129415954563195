import { useMutation, useQuery } from "react-query";
import { translationsService } from "../../services/translations.service";
import { Language } from "../models/translations/language.model";
import { SetTranslationWithKey } from "../models/translations/set-translation-with-key.model";
import { SetTranslation } from "../models/translations/set-translation.model";
import { TranslationKeyValue } from "../models/translations/translation-key-value.model";
import { TranslationKey } from "../models/translations/translation-key.model";
import { TranslationValue } from "../models/translations/translation-value.model";

export function useLanguagesQuery() {
  return useQuery<Language[]>(["languages"], () =>
    translationsService.getAllLanguages()
  );
}

export function useSelectedLanguagesQuery() {
  return useQuery<Language[]>(["languages", "selected-languages"], () =>
    translationsService.getSelectedLanguages()
  );
}

export function usePublishedLanguagesQuery() {
  return useQuery<Language[]>(["languages", "published-languages"], () =>
    translationsService.getPublishedLanguages()
  );
}

export function useSelectLanguageMutation() {
  return useMutation((id: number) =>
    translationsService.selectSingleLanguage(id)
  );
}

export function useUnselectLanguageMutation() {
  return useMutation((id: number) =>
    translationsService.unselectSingleLanguage(id)
  );
}

export function usePublishLanguageMutation() {
  return useMutation((id: number) =>
    translationsService.publishSingleLanguage(id)
  );
}

export function useUnpublishLanguageMutation() {
  return useMutation((id: number) =>
    translationsService.unpublishSingleLanguage(id)
  );
}

export function useAllTranslationKeysQuery() {
  return useQuery<TranslationKey[]>(["translations", "translation-keys"], () =>
    translationsService.getAllTranslationKeys()
  );
}

export function useAllTranslationsQuery() {
  return useQuery<TranslationValue[]>(
    ["translations", "translation-values"],
    () => translationsService.getAllTranslations()
  );
}

export function useLanguageTranslationsQuery(languageId: number) {
  return useQuery<TranslationKeyValue[]>(
    ["translations", "language-translations", languageId],
    () => translationsService.getLanguageTranslations(languageId)
  );
}

export function useCultureTranslationsQuery(culture: string) {
  return useQuery<TranslationKeyValue[]>(
    ["translations", "culture-translations", culture],
    () => translationsService.getCultureTranslations(culture)
  );
}

export function useCultureTranslationsJsonQuery(culture: string) {
  return useQuery<string>(
    ["translations", "culture-translations-json", culture],
    () => translationsService.getCultureTranslationsJson(culture)
  );
}

export function useSetTranslationMutation() {
  return useMutation((translation: SetTranslation) =>
    translationsService.setTranslation(translation)
  );
}

export function useSetTranslationWithKeyMutation() {
  return useMutation((translation: SetTranslationWithKey) =>
    translationsService.setTranslationWithKey(translation)
  );
}
