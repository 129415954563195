import { useQuery } from "react-query";
import { dashboardService } from "../../services/dashboard.service";

export function useDashboardQuery(communityId: number) {
  return useQuery(
    ["dashboard", communityId],
    () => dashboardService.getData(communityId),
    {
      enabled: !!communityId,
    }
  );
}
