import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoundedCard } from "../components/ui/RoundedCard";
import { useCommunitiesQuery } from "../queries/communities/communities.query";
import { Community } from "../queries/models/community-administration/community.model";
import { CreateCommunityDialog } from "../components/communities/CreateCommunityDialog";

export function Communities() {
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const { data } = useCommunitiesQuery();

  function goToDetails(id: number) {
    navigate(`/communities/${id}`);
  }

  return (
    <RoundedCard header="Communities">
      <div className="mt-2 mb-2">
        <Button
          label="Create community"
          onClick={(x) => setDialogVisible(true)}
        />
      </div>
      <DataTable value={data}>
        <Column
          field="id"
          header="ID"
        ></Column>
        <Column
          field="name"
          header="Name"
        ></Column>
        <Column
          field="members"
          header="Members"
          body={(rowData) => {
            return rowData.members.length;
          }}
        ></Column>
        <Column
          header=""
          style={{ width: "6rem" }}
          body={(rowData) => (
            <div className="flex flex-row gap-1">
              <Button
                label="Details"
                icon="pi pi-info-circle"
                className="p-button-warning"
                onClick={() => {
                  goToDetails(rowData.id);
                }}
              />
            </div>
          )}
        />
      </DataTable>

      <CreateCommunityDialog
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
      ></CreateCommunityDialog>
    </RoundedCard>
  );
}
