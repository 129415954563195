import { RoundedCard } from "../components/ui/RoundedCard";
import { CoinsBalance } from "../components/dashboard/CoinsBalance";
import { CoinsHistory } from "../components/dashboard/CoinsHistory";
import { MyEnergyCommunity } from "../components/dashboard/MyEnergyCommunity";
import { useDashboardQuery } from "../queries/dashboard/dashboard.query";
import { useEnergyCommunitySelector } from "../components/ui/EnergyCommunityContext";
import Savings from "../components/dashboard/Savings";
import { EnergyGenerated } from "../components/dashboard/EnergyGenerated";
import { CenteredLoader } from "../components/ui/CenteredLoader";

export function Dashboard() {
  const { selectedCommunityId } = useEnergyCommunitySelector();

  const dashboardQuery = useDashboardQuery(selectedCommunityId);
  var data = dashboardQuery.data;
  var isLoading = dashboardQuery.isLoading;

  return (
    <div className="flex flex-col">
      {isLoading && <CenteredLoader spinner />}
      {data && (
        <>
          <div className="flex flex-wrap">
            <div className="flex flex-col gap-1">
              <RoundedCard>
                <Savings
                  evSavingsEur={data.eurSavedOnCarCharging}
                  householdSavingsEur={data.eurSavedOnEnergy}
                />{" "}
              </RoundedCard>
            </div>
            <div className="flex">
              <RoundedCard header="Your energy community">
                <MyEnergyCommunity
                  chargingStations={data?.energyCommunity.chargingStations}
                  greenEnergyProduced={
                    data?.energyCommunity.greenEnergyProduced
                  }
                  members={data?.energyCommunity.members}
                  peakSolarPower={data?.energyCommunity.peakSolarPower}
                  peakWindPower={data?.energyCommunity.peakWindPower}
                  share={data?.energyCommunity.share}
                  query={dashboardQuery}
                />
              </RoundedCard>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="flex flex-col gap-1">
              <RoundedCard header="Energy coins balance">
                <CoinsBalance
                  coins={data?.coinsBalance}
                  eur={data?.coinsEurBalance}
                  query={dashboardQuery}
                />
              </RoundedCard>
              {data?.energyProducedWh !== undefined && (
                <RoundedCard header="Green energy generated">
                  <EnergyGenerated
                    energy={data.energyProducedWh}
                    query={dashboardQuery}
                  />
                </RoundedCard>
              )}
            </div>
            <RoundedCard header="Energy coins history">
              <CoinsHistory
                data={data?.coinsHistory.map((x) => {
                  return { date: x.date, value: x.coinsChange };
                })}
                query={dashboardQuery}
              />
            </RoundedCard>
          </div>
        </>
      )}
    </div>
  );
}
