import { useMutation, useQuery } from "react-query";
import { memberConfigurationService } from "../../services/member-administration.service";
import { Member } from "../models/communities/member.model";
import { CreateUpdateCommunityMember } from "../models/community-administration/create-update-community-member.model";

export function useCommunityMembersQuery(communityId: number | undefined) {
  return useQuery(
    ["members", "community", communityId],
    () => memberConfigurationService.getCommunityMembers(communityId!),
    {
      enabled: !!communityId,
    }
  );
}

export function useMemberQuery(id: number | undefined) {
  return useQuery<Member>(
    ["members", id],
    () => memberConfigurationService.getMember(id!),
    {
      enabled: !!id,
    }
  );
}

export function useCreateMemberMutation() {
  return useMutation((request: CreateUpdateCommunityMember) =>
    memberConfigurationService.createMember(request)
  );
}

export function useUpdateMemberMutation() {
  return useMutation((request: CreateUpdateCommunityMember) =>
    memberConfigurationService.updateMember(request.id!, request)
  );
}

export function useDeleteMemberMutation() {
  return useMutation((id: number) =>
    memberConfigurationService.deleteMember(id)
  );
}
