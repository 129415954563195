export enum CoinTransactionType {
  Unknown = 0,
  TransferBetweenMembers = 10,
  DispatchToMember = 20,
  ConsumptionFromMember = 21,
  MemberProduction = 30,
}

export interface CoinTransaction {
  date: Date;
  coins: number;
  type: CoinTransactionType;
}
