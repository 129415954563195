import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faMoneyBillTrendUp,
  faCity,
  faGears,
  faUsers,
  faWallet,
  faCar,
  faLanguage,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import { useWindowSize } from "../../hooks/use-window-size";
import { Permissions } from "../../utils/permissions";
import { SideBarNavigationDesktop } from "./side-bar-nav-desktop";
import { SideBarNavigationMobile } from "./side-bar-nav-mobile";
import { useMemo } from "react";

export interface MenuElement {
  to: string;
  label: string;
  icon: IconProp;
  permission?: Permissions;
}

export function SideBarNavigation() {
  const windowSize = useWindowSize();

  const menuElements: MenuElement[] = useMemo(() => {
    const elements = [
      {
        to: "dashboard",
        label: "Dashboard",
        icon: faMoneyBillTrendUp,
      },
      {
        to: "wallet",
        label: "Wallet",
        icon: faWallet,
      },
      {
        to: "ev-charging",
        label: "EV Charging",
        icon: faCar,
      },
      {
        to: "communities",
        label: "Communities",
        icon: faCity,
      },
      {
        to: "community-administration",
        label: "Community",
        icon: faGears,
        permission: Permissions.CommunityAdministration,
      },
      {
        to: "users",
        label: "Users",
        icon: faUsers,
        permission: Permissions.SecurityAdministration,
      },
      {
        to: "translations",
        label: "Translations",
        icon: faLanguage,
        permission: Permissions.ModifyTranslations,
      },
      {
        to: "sales-documents-administration",
        label: "Sales documents",
        icon: faFileContract,
      },
    ] as MenuElement[];

    return elements;
  }, []);

  return (
    <>
      {windowSize.lg ? (
        <SideBarNavigationDesktop menuElements={menuElements} />
      ) : (
        <SideBarNavigationMobile menuElements={menuElements} />
      )}
    </>
  );
}
