export enum Unit {
  none = "",
  euro = "EUR",
  watt = "W",
  amper = "A",
  volt = "V",
  wattHour = "Wh",
  percent = "%",
  state = "bool",
  celsius = "°C",
}

const SiPrefixes = {
  "": 1,
  k: 1_000,
  M: 1_000_000,
  G: 1_000_000_000,
} as const;

export type SiPrefixSymbol = keyof typeof SiPrefixes;
export type SiPrefixValue = (typeof SiPrefixes)[keyof typeof SiPrefixes];

const AnyPrefix = "?";
export type SiPrefixRange =
  | `${SiPrefixSymbol | typeof AnyPrefix}-${SiPrefixSymbol | typeof AnyPrefix}`
  | SiPrefixSymbol
  | typeof AnyPrefix;

export function toUnit(
  number: number,
  unit: string = "",
  prefixRange: SiPrefixRange = AnyPrefix
): ValueWithUnit {
  const prefixesValues = Object.entries(SiPrefixes) as [
    SiPrefixSymbol,
    SiPrefixValue
  ][];

  const selectedPrefixes = prefixRange
    .split("-")
    .map((p) => (p === AnyPrefix ? undefined : p));

  const minZoom = SiPrefixes[selectedPrefixes[0] as SiPrefixSymbol];
  const maxZoom =
    selectedPrefixes.length === 1
      ? minZoom
      : SiPrefixes[selectedPrefixes[1] as SiPrefixSymbol];

  const prefix = prefixesValues
    .filter((x) => minZoom === undefined || minZoom <= x[1])
    .filter((x) => maxZoom === undefined || x[1] <= maxZoom)
    .filter((x) => x[1] <= Math.abs(number))
    .reduce((a, b) => (a[1] < b[1] ? b : a), [
      selectedPrefixes[0] ?? "",
      minZoom ?? 1,
    ] as [SiPrefixSymbol, SiPrefixValue]);

  const value = number / prefix[1];

  return new ValueWithUnit(value, `${prefix[0]}${unit}`);
}

export class ValueWithUnit extends String {
  public value: string;
  public unit: string;
  public formatted: string;

  constructor(value: number, unit: string) {
    super();
    const fractionDigits = 2;
    this.value = (+value.toFixed(fractionDigits)).toFixed(fractionDigits);
    this.unit = unit;
    this.formatted = `${this.value} ${this.unit}`;
  }

  public toString(): string {
    return this.formatted;
  }

  public at(index: number) {
    return this.formatted.at(index);
  }
}
