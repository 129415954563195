import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataLoader } from "../ui/DataLoader";
import { UseQueryResult } from "react-query";

interface EnergyCommunityItemProps {
  icon: IconDefinition;
  text?: string;
  query?: UseQueryResult;
}

export function EnergyCommunityItem(props: EnergyCommunityItemProps) {
  return (
    <div className="flex flex-row items-center mb-2">
      <FontAwesomeIcon
        icon={props.icon}
        className="text-blue-500 text-3xl mr-2"
      />
      <DataLoader
        query={props.query}
        className="text-center text-slate-700"
      >
        {props.text}
      </DataLoader>
    </div>
  );
}
