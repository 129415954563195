import { Dialog } from "primereact/dialog";
import { useToast } from "../ui/ToastContext";
import { useCallback } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useQueryClient } from "react-query";
import { EnergyMeter } from "../../queries/models/community-administration/energy-meter.model";
import {
  useCreateEnergyMeterMutation,
  useDeleteEnergyMeterMutation,
  useUpdateEnergyMeterMutation,
} from "../../queries/energy-meter-administraion/energy-meter-administration.query";
import { CreateUpdateEnergyMeter } from "../../queries/models/community-administration/create-update-energy-meter.model";
import { EnergyMeterForm } from "./EnergyMeterForm";
import { Member } from "../../queries/models/communities/member.model";

export interface EnergyMeterDialogProps {
  visible: boolean;
  subject: EnergyMeter | undefined;
  member: Member | undefined;
  onHide: () => void;
}

export function EnergyMeterDialog({
  visible,
  subject,
  member,
  onHide,
}: EnergyMeterDialogProps) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const createEnergyMeterMutation = useCreateEnergyMeterMutation();
  const updateEnergyMeterMutation = useUpdateEnergyMeterMutation();
  const deleteEnergyMeterMutation = useDeleteEnergyMeterMutation();

  const handleSave = useCallback(
    (model: CreateUpdateEnergyMeter) => {
      if (subject?.ean) {
        const mutateOptions = {
          onSuccess: async () => {
            await queryClient.invalidateQueries("members");
            onHide();
            toast.current?.show({
              severity: "success",
              detail: "Energy meter added",
            });
          },
          onError: async (error: any) => {
            toast.current?.show({
              severity: "error",
              detail: error.response?.data ?? error.message,
            });
          },
        };

        updateEnergyMeterMutation.mutateAsync(model, mutateOptions);
      } else {
        const mutateOptions = {
          onSuccess: async () => {
            await queryClient.invalidateQueries("members");
            onHide();
            toast.current?.show({
              severity: "success",
              detail: "Energy meter updated",
            });
          },
          onError: async (error: any) => {
            toast.current?.show({
              severity: "error",
              detail: error.response?.data ?? error.message,
            });
          },
        };

        createEnergyMeterMutation.mutateAsync(model, mutateOptions);
      }
    },
    [
      createEnergyMeterMutation,
      onHide,
      queryClient,
      subject?.ean,
      toast,
      updateEnergyMeterMutation,
    ]
  );

  const handleDelete = useCallback(
    (model: EnergyMeter) => {
      const mutateOptions = {
        onSuccess: async () => {
          await queryClient.invalidateQueries("members");
          onHide();
          toast.current?.show({
            severity: "success",
            detail: "Energy meter deleted",
          });
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error.response?.data ?? error.message,
          });
        },
      };

      deleteEnergyMeterMutation.mutateAsync(model.ean, mutateOptions);
    },
    [deleteEnergyMeterMutation, onHide, queryClient, toast]
  );

  return (
    <>
      <Dialog
        className="max-w-full"
        visible={visible}
        header="Energy meter"
        onHide={onHide}
      >
        <div className="flex">
          <EnergyMeterForm
            onSave={handleSave}
            onDelete={handleDelete}
            onCancel={onHide}
            subject={subject}
            member={member}
          />
        </div>
      </Dialog>
      <ConfirmDialog />
    </>
  );
}
