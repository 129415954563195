import { DataTable } from "primereact/datatable";
import { useNavigate, useParams } from "react-router-dom";
import { useCommunityQuery } from "../queries/communities/communities.query";
import { useCallback, useMemo, useState } from "react";
import { Column } from "primereact/column";
import { BackButton } from "../components/ui/BackButton";
import { DetailsRow } from "../components/ui/DetailsRow";
import { RoundedCard } from "../components/ui/RoundedCard";
import { Button } from "primereact/button";
import { DetailsContainer } from "../components/ui/DetailsContainer";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useDeleteCommunityMutation } from "../queries/community-administration/community-administration.query";
import { useQueryClient } from "react-query";
import { useToast } from "../components/ui/ToastContext";
import { CreateMemberDialog } from "../components/communities/CreateMemberDialog";

export function CommunityDetails() {
  const { id } = useParams();
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();

  const communityQuery = useCommunityQuery(Number(id));
  const deleteCommunityMutation = useDeleteCommunityMutation();

  const members = useMemo(() => {
    if (communityQuery.data) {
      return communityQuery.data.members;
    }
  }, [communityQuery.data]);

  const handleAddMember = useCallback(() => {
    setDialogVisible(true);
  }, []);

  const handleDeleteCommunity = useCallback(() => {
    confirmDialog({
      message: "Are you sure you want permanently archive this community?",
      header: "Confirmation",
      icon: "pi pi-question-circle text-green-600",
      accept: () => {
        const mutateOptions = {
          onSuccess: async () => {
            await queryClient.invalidateQueries("communities");
            navigate("/communities");
            toast.current?.show({
              severity: "success",
              detail: "Community archived!",
            });
          },
          onError: async (error: any) => {
            toast.current?.show({
              severity: "error",
              detail: error.response?.data ?? error.message,
            });
          },
        };
        deleteCommunityMutation.mutateAsync(Number(id), mutateOptions);
      },
    });
  }, [deleteCommunityMutation, id, navigate, queryClient, toast]);

  function goToDetails(id: number) {
    navigate(`/members/${id}`);
  }

  return (
    <RoundedCard>
      <div className="flex flex-row justify-between">
        <BackButton onClick={() => navigate("/communities")} />

        <div className="flex flex-row gap-1 mt-2">
          <Button
            label="Add member"
            icon="pi pi-plus"
            className="p-button-success"
            onClick={handleAddMember}
          />
        </div>
        <div className="flex flex-row gap-1 mt-2">
          <Button
            label="Delete community"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={handleDeleteCommunity}
          />
        </div>
      </div>
      <DetailsContainer>
        <DetailsRow
          label="Id"
          value={communityQuery.data?.id}
        />
        <DetailsRow
          label="Name"
          value={communityQuery.data?.name}
        />
      </DetailsContainer>

      <DataTable value={members}>
        <Column
          field="id"
          header="ID"
        />
        <Column
          field="name"
          header="Name"
        />
        <Column
          field="meters"
          header="Meters"
        />
        <Column
          field="investment"
          header="Investment [€]"
        />
        <Column
          field="share"
          header="Share"
          body={(x) => (x.share * 100).toFixed(0) + "%"}
        />
        <Column
          field="coins"
          header="Energy coins"
        />
        <Column
          header=""
          style={{ width: "6rem" }}
          body={(rowData) => (
            <div className="flex flex-row gap-1">
              <Button
                label="Details"
                icon="pi pi-info-circle"
                className="p-button-warning"
                onClick={() => {
                  goToDetails(rowData.id);
                }}
              />
            </div>
          )}
        />
      </DataTable>
      <ConfirmDialog />

      <CreateMemberDialog
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        communityId={Number(id)}
      ></CreateMemberDialog>
    </RoundedCard>
  );
}
