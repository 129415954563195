import { useQuery } from "react-query";
import { carsService } from "../../services/cars.service";

export function useCarsQuery(communityId: number) {
  return useQuery(
    ["cars", communityId],
    () => carsService.getCars(communityId),
    {
      enabled: !!communityId,
    }
  );
}
