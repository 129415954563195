import { Dialog } from "primereact/dialog";
import { User } from "../../queries/models/users/user.model";
import { useToast } from "../ui/ToastContext";
import { UserEditForm } from "./UserEditForm";
import { SelectItem } from "primereact/selectitem";
import { Button } from "primereact/button";
import { useCallback } from "react";
import {
  useAddUserMutation,
  useEditUserMutation,
} from "../../queries/users/users.query";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { EditUser } from "../../queries/users/edit-user";
import { use } from "i18next";
import { useQueryClient } from "react-query";

export interface UserDialogProps {
  selectedUser?: User;
  setSelectedUser(user: User): void;
  visible: boolean;
  onHide: () => void;
  roleOptions: SelectItem[];
}

export function UserDialog({
  selectedUser,
  visible,
  roleOptions,
  onHide,
  setSelectedUser,
}: UserDialogProps) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const addUserMutation = useAddUserMutation();
  const editUserMutation = useEditUserMutation();

  const onSave = useCallback(() => {
    if (selectedUser) {
      confirmDialog({
        message: "Are you sure you want to save this user?",
        header: "Save user",
        icon: "pi pi-question-circle text-green-600",
        accept: () => {
          let editUser: EditUser = {
            email: selectedUser.email,
            roleId: selectedUser.role?.id,
            communityIds: [], //TODO
          };

          const mutateOptions = {
            onSuccess: async () => {
              await queryClient.invalidateQueries("users");
              onHide();
              toast.current?.show({
                severity: "success",
                detail: "User saved",
              });
            },
            onError: async (error: any) => {
              toast.current?.show({
                severity: "error",
                detail: error.response?.data ?? error.message,
              });
            },
          };

          if (selectedUser.id) {
            editUserMutation.mutateAsync(
              {
                id: selectedUser.id,
                user: editUser,
              },
              mutateOptions
            );
          } else {
            addUserMutation.mutateAsync(editUser, mutateOptions);
          }
        },
      });
    }
  }, [
    addUserMutation,
    editUserMutation,
    onHide,
    queryClient,
    selectedUser,
    toast,
  ]);

  return (
    <>
      <Dialog
        className="max-w-full"
        visible={visible}
        header="User"
        onHide={onHide}
      >
        {selectedUser && (
          <div className="flex flex-col gap-2">
            <UserEditForm
              user={selectedUser}
              onChange={setSelectedUser}
              roleOptions={roleOptions}
            />
            <div className="flex">
              <Button
                label="Save"
                className="p-button p-button-success"
                onClick={onSave}
              />
            </div>
          </div>
        )}
      </Dialog>
      <ConfirmDialog />
    </>
  );
}
