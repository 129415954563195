import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/number-utils";
import { useMemo } from "react";

interface SavingsProps {
  householdSavingsEur: number;
  evSavingsEur: number | null;
}

export default function Savings({
  householdSavingsEur,
  evSavingsEur,
}: SavingsProps) {
  const { t } = useTranslation();

  const totalSavingsEur = useMemo(() => {
    return householdSavingsEur + (evSavingsEur ?? 0);
  }, [householdSavingsEur, evSavingsEur]);

  return (
    <>
      <div className="flex flex-col justify-center h-full">
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <div>
              <img
                className="w-24 p-1"
                src="/images/total_savings.png"
                alt="img"
              />
            </div>
            <div className="flex flex-col flex-grow">
              <div className="flex flex-row justify-between items-center">
                <div>
                  <p className="text-xl text-gray-600 ">{t("Total savings")}</p>
                </div>
                <div className="pl-2 ml-auto">
                  <h2 className="text-primary text-xl font-semibold">
                    {formatNumber(totalSavingsEur, 2, 2)} €
                  </h2>
                </div>
              </div>
              {evSavingsEur !== null && (
                <div className="flex flex-row justify-between items-center gap-3">
                  <div>
                    <p className="text-xl text-gray-600">
                      {t("Building energy savings")}
                    </p>
                  </div>
                  <div className="pl-2 ml-auto">
                    <h2 className="text-primary text-xl font-semibold">
                      {formatNumber(householdSavingsEur, 2, 2)} €
                    </h2>
                  </div>
                </div>
              )}
            </div>
          </div>
          {evSavingsEur !== null && (
            <div className="flex flex-row items-center">
              <div>
                <img
                  className="w-24 p-1"
                  src="/images/ev.jpeg"
                  alt="img"
                />
              </div>
              <div className="flex flex-col flex-grow">
                <div className="flex flex-row justify-between items-center gap-3">
                  <div>
                    <p className="text-xl text-gray-600">
                      {t("Electric vehicle savings")}
                    </p>
                  </div>
                  <div className="pl-2 ml-auto">
                    <h2 className="text-primary text-xl font-semibold">
                      {formatNumber(evSavingsEur, 2, 2)} €
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
