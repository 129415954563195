import { useMutation, useQuery } from "react-query";
import { Community } from "../models/community-administration/community.model";
import { communityConfigurationService } from "../../services/community-administration.service";
import { CreateUpdateCommunity } from "../models/community-administration/create-update-community.model";

export function useCommunityQuery(id: number | undefined) {
  return useQuery<Community>(
    ["community-administration", id],
    () => communityConfigurationService.getCommunity(id!),
    {
      enabled: !!id,
    }
  );
}

export function useCreateCommunityMutation() {
  return useMutation((request: CreateUpdateCommunity) =>
    communityConfigurationService.createCommunity(request)
  );
}

export function useUpdateCommunityMutation() {
  return useMutation((request: CreateUpdateCommunity) =>
    communityConfigurationService.updateCommunity(request.id!, request)
  );
}

export function useDeleteCommunityMutation() {
  return useMutation((id: number) =>
    communityConfigurationService.deleteCommunity(id)
  );
}
