import { useMemo } from "react";
import { Permissions } from "../utils/permissions";
import { useAuth } from "../auth/AuthProvider";

export function usePermissions() {
  const auth = useAuth();

  function hasPermission(permission: Permissions) {
    return permissions.some((p) => p === permission);
  }

  const permissions = useMemo(() => {
    return auth.userData?.permissions as Permissions[];
  }, [auth.userData]);

  return { permissions, hasPermission };
}
