import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

export function NoCommunitiesInfo() {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center h-screen items-center">
      <div className="w-50 flex flex-col items-center">
        <span>{t("users.noInstallationsAssignedError")}</span>
        <div>
          <Button label="Logout" />
        </div>
      </div>
    </div>
  );
}
