import { ReactNode } from "react";

interface ContentWithLabelProps {
  label: string;
  children: ReactNode;
  // icon: string;
}

export function ContentWithLabel({ label, children }: ContentWithLabelProps) {
  return (
    <>
      <div className="bg-gray-100 my-3 py-2 px-3 rounded-3xl relative break-inside-avoid inline-block">
        <label className="font-semibold text-md mb-1 py-2 block text-gray-500">
          {label}
        </label>
        {children}
      </div>
    </>
  );
}
