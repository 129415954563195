import { CreateUpdateSalesDocument } from "../queries/models/sales-documents/create-update-sales-document.model";
import { SalesDocument } from "../queries/models/sales-documents/sales-document.model";
import { BaseService } from "./base.service";

class SalsesDocumentsService extends BaseService {
  async getSalesDocument(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.get<SalesDocument>(url).then((res) => res.data);
  }
  async getCommunitySalesDocuments(
    communityId: number,
    from: Date | undefined,
    to: Date | undefined
  ) {
    const url = `${this.endpointPath}/community/${communityId}`;
    return this.http
      .get<SalesDocument[]>(url, {
        params: {
          from,
          to,
        },
      })
      .then((res) => res.data);
  }
  async createSalesDocument(model: CreateUpdateSalesDocument) {
    const url = `${this.endpointPath}`;
    return this.http.post<SalesDocument>(url, model).then((res) => res.data);
  }
  async updateSalesDocument(id: number, model: CreateUpdateSalesDocument) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.put(url, model);
  }
  async deleteSalesDocument(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.delete(url);
  }
}

export const salsesDocumentsService = new SalsesDocumentsService(
  "administration/sales-documents"
);
