import { Button } from "primereact/button";

interface BackButtonProps {
  onClick: () => void;
}

export function BackButton({ onClick }: BackButtonProps) {
  return (
    <div className="flex flex-row gap-1 mt-2">
      <Button
        label="Back"
        icon="pi pi-arrow-left"
        className="p-button-warning"
        onClick={onClick}
      />
    </div>
  );
}
