import { DataTable } from "primereact/datatable";
import { useCarsQuery } from "../../queries/ev/cars.query";
import { Column } from "primereact/column";
import { useEnergyCommunitySelector } from "../ui/EnergyCommunityContext";

export function Cars() {
  const { selectedCommunityId } = useEnergyCommunitySelector();
  const carsQuery = useCarsQuery(selectedCommunityId);

  const cars = carsQuery.data;

  return (
    <div>
      <DataTable value={cars}>
        <Column
          field="id"
          header="Id"
        ></Column>
        <Column
          field="name"
          header="Name"
        ></Column>
        <Column
          field="capacity"
          header="Capacity"
        ></Column>
        <Column
          field="maxChargingPower"
          header="Max Charging Power"
        ></Column>
      </DataTable>
    </div>
  );
}
