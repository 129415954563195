import { sideBarAtom } from "../../atoms/side-bar-atom";
import { SideBarElementsDesktop } from "./side-bar-elements-desktop";
import { useAtom } from "jotai";
import { classNames } from "primereact/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { MenuElement } from "./side-bar-nav";

export function SideBarNavigationDesktop({
  menuElements,
}: {
  menuElements: MenuElement[];
}) {
  const [sidebarState, toggleSidebar] = useAtom(sideBarAtom);
  return (
    <div className="flex pt-14 fixed h-full z-5">
      <div
        className={classNames(
          "items-center m-2 shadow-xl p-1 py-5 flex justify-between flex-col bg-gradient-to-b from-green-500 to-sky-500",
          sidebarState.expanded
            ? "w-12rem rounded-3xl w-[180px]"
            : "rounded-full w-16"
        )}
      >
        <SideBarElementsDesktop menuElements={menuElements} />
        <div
          onClick={() => toggleSidebar({ expanded: !sidebarState.expanded })}
          className={classNames(
            "cursor-pointer flex justify-content-center m-2 transition-all duration-200 ease-in-out",
            sidebarState.expanded ? "" : "rotate-180"
          )}
        >
          <FontAwesomeIcon
            icon={faCircleChevronLeft}
            className="text-white transition-all duration-200 ease-in-out"
          />
        </div>
      </div>
    </div>
  );
}
