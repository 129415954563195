import { Dropdown } from "primereact/dropdown";
import { useCommunitiesForSelectorQuery } from "../../queries/communities/communities.query";

interface EnergyCommunitySelectorProps {
  selectedCommunityId: number;
  onCommunityIdChange: (communityId: number) => void;
}

export function EnergyCommunitySelector({
  selectedCommunityId,
  onCommunityIdChange,
}: EnergyCommunitySelectorProps) {
  const { data } = useCommunitiesForSelectorQuery();

  return (
    <div>
      {data && data.length > 1 && (
        <Dropdown
          options={data}
          onChange={(e) => onCommunityIdChange(e.value)}
          value={selectedCommunityId}
          optionLabel="name"
          optionValue="id"
          className="mr-2 !w-56 shadow-md"
        />
      )}
    </div>
  );
}
