import { useQuery } from "react-query";
import { chargingSessionsService } from "../../services/charging-sessions.service";

export function useChargingSessionsQuery(
  communityId: number,
  from: Date,
  to: Date
) {
  return useQuery(
    ["chargingSessions", communityId, from, to],
    () => chargingSessionsService.getChargingSessions(communityId, from, to),
    {
      enabled: !!communityId,
    }
  );
}
