export function roundUpToNearestLog(num: number): number {
  if (num === 0) {
    return 0;
  }

  const log = Math.floor(Math.log10(Math.abs(num)));

  const roundingNumber = Math.pow(10, log - 1);

  if (num > 0) {
    return Math.ceil(num / roundingNumber) * roundingNumber;
  } else {
    return Math.floor(num / roundingNumber) * roundingNumber;
  }
}

export function formatNumber(
  num: number,
  maxDigits?: number,
  minDigits?: number
): string;
export function formatNumber(
  num: undefined,
  maxDigits?: number,
  minDigits?: number
): undefined;
export function formatNumber(
  num?: number,
  maxDigits?: number,
  minDigits?: number
): string | undefined;
export function formatNumber(
  num?: number,
  maxDigits: number = 2,
  minDigits: number = 0
): string | undefined {
  return num?.toLocaleString(undefined, {
    maximumFractionDigits: maxDigits,
    minimumFractionDigits: minDigits,
  });
}

export function roundToDecimals(num: number, decimals: number): number {
  const factor = Math.pow(10, decimals);
  return Math.round(num * factor) / factor;
}
