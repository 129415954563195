import { CreateUpdateCommunity } from "../../queries/models/community-administration/create-update-community.model";
import * as Yup from "yup";
import { useCallback } from "react";
import { Form, Formik } from "formik";
import { FormToolbar } from "../ui/form-toolbar";
import { useToast } from "../ui/ToastContext";
import FormikInputText from "../formik/FormikInputText";

interface CreateCommunityFormProps {
  onSave: (model: CreateUpdateCommunity) => void;
  onCancel: () => void;
}

export function CreateCommunityForm({
  onSave: onChange,
  onCancel,
}: CreateCommunityFormProps) {
  const initialValues: CreateUpdateCommunity = {
    name: "",
  };
  const toast = useToast();
  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, "Must be 3 characters or more")
      .required("Required"),
  });

  const onSubmit = useCallback(
    (values: CreateUpdateCommunity) => {
      return onChange(values);
    },
    [onChange]
  );

  return (
    <div className="h-full w-full overflow-auto min-w-80">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
        validateOnMount
      >
        {(formik) => (
          <Form className="flex h-full flex-col">
            <div className="formgrid grid mb-4 p-1">
              <div className="field col-12 md:col-6">
                <FormikInputText
                  validationSchema={validationSchema}
                  label="Name"
                  name="name"
                  isIndependent
                  disabled={false}
                />
              </div>
            </div>

            <FormToolbar
              showDeleteButton={false}
              saveButtonDisabled={!formik.dirty || !formik.isValid}
              onSaveClick={async () => {
                if (!formik.isValid) {
                  toast.current?.show({
                    severity: "error",
                    detail: "Form invalid",
                  });
                  return;
                }
                return formik.submitForm();
              }}
              onCancelClick={onCancel}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
