import { AxiosRequestHeaders, AxiosResponse } from "axios";
import { useEffect, useState } from "react";

import AxiosInstance from "../../services/request";
import { handleDates } from "../../utils/serialize-date-helpers";

import i18n from "i18next";
import { LocalStorageService } from "../../services/local-storage.service";
import { useAuth } from "../../auth/AuthProvider";

const AxiosInterceptor = ({ children }: { children: any }) => {
  const [isSet, setIsSet] = useState(false);
  const auth = useAuth();

  function getCurrentToken() {
    return auth.userData?.accessToken;
  }

  useEffect(() => {
    const authInterceptor = (config: any) => {
      const token = getCurrentToken();
      const header = token ? `Bearer ${token}` : null;

      config.headers = {
        ...config.headers,
        Authorization: header ?? "",
      } as AxiosRequestHeaders;

      return config;
    };

    const languageInterceptor = (config: any) => {
      config.headers = {
        ...config.headers,
        "Language-Culture": i18n.language,
      };
      return config;
    };

    const errInterceptor = (error: any) => {
      return Promise.reject(error);
    };

    const passThroughInterceptor = (response: AxiosResponse<any, any>) =>
      response;

    const authErrorInterceptor = (error: any) => {
      if (error.response) {
        if (
          error.response.status === 401 ||
          (error.response.status === 403 && getCurrentToken())
        ) {
          window.location.href = "/login";
          return;
        }
      }
      return Promise.reject(error);
    };

    const interceptor = AxiosInstance.interceptors.request.use(
      authInterceptor,
      errInterceptor
    );

    const langInterceptor = AxiosInstance.interceptors.request.use(
      languageInterceptor,
      errInterceptor
    );

    const responseErrorInterceptor = AxiosInstance.interceptors.response.use(
      passThroughInterceptor,
      authErrorInterceptor
    );

    const datesInterceptor = AxiosInstance.interceptors.response.use(
      (response) => {
        handleDates(response.data);
        return response;
      }
    );

    const refreshTokenInterceptor = AxiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        const newToken = response.headers["x-refresh-token"];
        if (newToken) {
          let loggedUser = LocalStorageService.get("AUTH_USER_INFO");

          if (loggedUser) {
            loggedUser.accessToken = newToken;
            LocalStorageService.set("AUTH_USER_INFO", loggedUser);
          }
        }

        return response;
      }
    );

    setIsSet(true);

    return () => {
      AxiosInstance.interceptors.request.eject(interceptor);
      AxiosInstance.interceptors.response.eject(responseErrorInterceptor);
      AxiosInstance.interceptors.response.eject(datesInterceptor);
      AxiosInstance.interceptors.response.eject(refreshTokenInterceptor);
      AxiosInstance.interceptors.request.eject(langInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isSet && children;
};

export default AxiosInstance;
export { AxiosInterceptor };
