import { CoinTransactionType } from "../queries/models/wallet/coin-transaction.model";

export const coinTransactionTypesNames: {
  [key in CoinTransactionType]: string;
} = {
  [CoinTransactionType.Unknown]: "Unknown",
  [CoinTransactionType.DispatchToMember]: "Earnings from community assets",
  [CoinTransactionType.ConsumptionFromMember]: "Member consumption",
  [CoinTransactionType.MemberProduction]: "Member self production",
  [CoinTransactionType.TransferBetweenMembers]: "Coins transfer",
};
