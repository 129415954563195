import { InputText } from "primereact/inputtext";
import { User } from "../../queries/models/users/user.model";
import { Dropdown } from "primereact/dropdown";
import { SelectItem } from "primereact/selectitem";
import { ContentWithLabel } from "../ui/ContentWithLabel";

interface UserEditFormProps {
  user: User;
  roleOptions: SelectItem[];
  onChange: (user: User) => void;
}

export function UserEditForm({
  user,
  roleOptions,
  onChange,
}: UserEditFormProps) {
  return (
    <form className="flex flex-col max-w-full w-[600px]">
      <ContentWithLabel label="Email">
        <InputText
          type="email"
          value={user.email ?? ""}
          onChange={(e) => onChange({ ...user, email: e.target.value })}
          required
        />
      </ContentWithLabel>

      <ContentWithLabel label="Role">
        <Dropdown
          value={user.role?.id}
          options={roleOptions}
          className="shadow-md"
          onChange={(e) =>
            onChange({ ...user, role: { ...user.role, id: e.value } })
          }
          required
        />
      </ContentWithLabel>
    </form>
  );
}
