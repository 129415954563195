import { CommunityDetails } from "../queries/models/communities/community-details.model";
import { BaseService } from "./base.service";

class CommunitiesService extends BaseService {
  async getCommunities(): Promise<CommunityDetails[]> {
    const url = `${this.endpointPath}`;
    return this.http.get<CommunityDetails[]>(url).then((res) => res.data);
  }

  async getCommunity(id: number): Promise<CommunityDetails> {
    const url = `${this.endpointPath}/${id}`;
    return this.http.get<CommunityDetails>(url).then((res) => res.data);
  }
}

export const communitiesService = new CommunitiesService(
  "administration/communities"
);
