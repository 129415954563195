import { Cars } from "../components/ev/Cars";
import { ChargingSessions } from "../components/ev/ChargingSessions";
import { RoundedCard } from "../components/ui/RoundedCard";

export function EvCharging() {
  return (
    <div>
      <RoundedCard header="My cars">
        <Cars />
      </RoundedCard>
      <RoundedCard header="Charging sessions">
        <ChargingSessions />
      </RoundedCard>
    </div>
  );
}
