import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../components/ui/BackButton";
import { DetailsRow } from "../components/ui/DetailsRow";
import { useUserQuery, useUsersQuery } from "../queries/users/users.query";
import { useMemo } from "react";
import { RoundedCard } from "../components/ui/RoundedCard";

export function UserDetails() {
  const userId = useParams().id;
  const navigate = useNavigate();

  const userQuery = useUserQuery(Number(userId));

  const user = useMemo(() => {
    if (userQuery.data) {
      return userQuery.data;
    }
  }, [userQuery.data]);

  return (
    <RoundedCard>
      <BackButton onClick={() => navigate("/users")} />
      {user && (
        <>
          <DetailsRow
            label="Id"
            value={user.id}
          />
          <DetailsRow
            label="Email"
            value={user.email}
          />
          <DetailsRow
            label="Role"
            value={user.role?.name ?? ""}
          />
        </>
      )}
    </RoundedCard>
  );
}
