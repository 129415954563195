import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faCog } from "@fortawesome/free-solid-svg-icons";
import { useWindowSize } from "../../hooks/use-window-size";

import { LanguageDropdown } from "./language-dropdown";
import { useNavigate } from "react-router";
import { EnergyCommunitySelector } from "../ui/EnergyCommunitySelector";
import { useEnergyCommunitySelector } from "../ui/EnergyCommunityContext";
import { useAuth } from "../../auth/AuthProvider";

export function TopBarNavigation() {
  const navigate = useNavigate();
  const auth = useAuth();

  const { selectedCommunityId, setSelectedCommunityId } =
    useEnergyCommunitySelector();

  function logout() {
    auth.signOut();
  }

  function openSettings() {
    navigate("/settings");
  }

  const windowSize = useWindowSize();

  return (
    <div className="px-4 bg-primary h-14 flex items-center fixed w-full z-50">
      <div className="items-center flex">
        <a href="/">
          {windowSize.sm ? (
            <img
              className="w-[220px] lg:pl-0 lg:p-3"
              src="/logo_horizontal_white.webp"
              alt="logo"
            />
          ) : (
            <img
              className="w-[50px] pr-2"
              src="/logo_sign_white.svg"
              alt="logo"
            />
          )}
        </a>
      </div>
      <div className="flex-row-reverse flex w-full items-center">
        <span
          className="flex rounded-full bg-teal-600 w-8 h-8 justify-center items-center cursor-pointer"
          onClick={logout}
        >
          <FontAwesomeIcon
            icon={faPowerOff}
            className="text-white"
          />
        </span>
        <span
          className="flex w-8 h-8 justify-center items-center cursor-pointer mr-2"
          onClick={openSettings}
        >
          <FontAwesomeIcon
            icon={faCog}
            className="text-white"
          />
        </span>
        <div className="sm:pr-2 pr-1">
          <EnergyCommunitySelector
            selectedCommunityId={selectedCommunityId}
            onCommunityIdChange={setSelectedCommunityId}
          />
        </div>
        {windowSize.sm && <LanguageDropdown></LanguageDropdown>}
      </div>
    </div>
  );
}
