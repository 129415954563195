import { CreateUpdateEnergyMeter } from "../queries/models/community-administration/create-update-energy-meter.model";
import { EnergyMeter } from "../queries/models/community-administration/energy-meter.model";
import { BaseService } from "./base.service";

class EnergyMeterAdministrationService extends BaseService {
  async getCommunityEnergyMeters(communityId: number): Promise<EnergyMeter[]> {
    const url = `${this.endpointPath}/community/${communityId}`;
    return this.http.get(url).then((res) => res.data);
  }
  async getEnergyMeter(ean: string) {
    const url = `${this.endpointPath}/${ean}`;
    return this.http.get(url).then((res) => res.data);
  }
  async createEnergyMeter(model: CreateUpdateEnergyMeter) {
    const url = `${this.endpointPath}`;
    return this.http.post(url, model).then((res) => res.data);
  }
  async updateEnergyMeter(ean: string, model: CreateUpdateEnergyMeter) {
    const url = `${this.endpointPath}/${ean}`;
    return this.http.put(url, model).then((res) => res.data);
  }
  async deleteEnergyMeter(ean: string) {
    const url = `${this.endpointPath}/${ean}`;
    return this.http.delete(url).then((res) => res.data);
  }
}

export const energyMeterAdministrationService =
  new EnergyMeterAdministrationService(
    "administration/energy-meters-configuration"
  );
