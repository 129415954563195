import { Role } from "../queries/models/users/role.model";
import { BaseService } from "./base.service";

class RolesService extends BaseService {
  async getAllRoles(): Promise<Role[]> {
    const url = `${this.endpointPath}`;
    return this.http.get<Role[]>(url).then((res) => res.data);
  }
}

export const rolesService = new RolesService("administration/roles");
