import { DataTable } from "primereact/datatable";
import { useUsersQuery } from "../queries/users/users.query";
import { Column } from "primereact/column";
import { User } from "../queries/models/users/user.model";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { RoundedCard } from "../components/ui/RoundedCard";
import { useMemo, useState } from "react";
import { UserDialog } from "../components/users/UserDialog";
import { useRolesQuery } from "../queries/users/roles.query";
import { SelectItem } from "primereact/selectitem";

export function Users() {
  const usersQuery = useUsersQuery();
  const rolesQuery = useRolesQuery();

  const [selectedUser, setSelectedUser] = useState<User>();
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  const navigate = useNavigate();

  const roleOptions: SelectItem[] = useMemo(() => {
    return (
      rolesQuery.data?.map((x) => ({
        value: x.id,
        label: x.name,
      })) ?? []
    );
  }, [rolesQuery.data]);

  function goToDetails(id: number) {
    navigate(`/users/${id}`);
  }

  function showEmptyDialog() {
    setDialogVisible(true);
    setSelectedUser({} as User);
  }

  function showEditDialog(user: User) {
    setDialogVisible(true);
    setSelectedUser(user);
  }

  return (
    <RoundedCard header="Users">
      <div className="mt-2 mb-2">
        <Button
          label="Create user"
          onClick={showEmptyDialog}
        />
      </div>

      {usersQuery.data && (
        <DataTable
          value={usersQuery.data}
          selectionMode="single"
          onRowClick={(e) => showEditDialog(e.data as User)}
        >
          <Column
            field="id"
            header="ID"
          ></Column>
          <Column
            field="email"
            header="Email"
          ></Column>
          <Column
            field="role"
            header="Role"
            body={(rowData: User) => <span>{rowData.role?.name}</span>}
          ></Column>
          <Column
            field="communities"
            header="Communities"
            body={(rowData: User) => <span>TODO</span>}
          ></Column>
          <Column
            header=""
            style={{ width: "6rem" }}
            body={(rowData) => (
              <div className="flex flex-row gap-1">
                <Button
                  label="Details"
                  icon="pi pi-info-circle"
                  className="p-button-warning"
                  onClick={() => {
                    goToDetails(rowData.id);
                  }}
                />
              </div>
            )}
          />
        </DataTable>
      )}

      <UserDialog
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        roleOptions={roleOptions}
      ></UserDialog>
    </RoundedCard>
  );
}
