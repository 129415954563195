import { CreateUpdateCommunity } from "../queries/models/community-administration/create-update-community.model";
import { BaseService } from "./base.service";

class CommunityAdministrationService extends BaseService {
  async getCommunity(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.get(url).then((res) => res.data);
  }
  async createCommunity(model: CreateUpdateCommunity) {
    const url = `${this.endpointPath}`;
    return this.http.post(url, model).then((res) => res.data);
  }
  async updateCommunity(id: number, model: CreateUpdateCommunity) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.put(url, model).then((res) => res.data);
  }
  async deleteCommunity(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.delete(url).then((res) => res.data);
  }
}

export const communityConfigurationService = new CommunityAdministrationService(
  "administration/communities-configuration"
);
