import { useMutation, useQuery, useQueryClient } from "react-query";
import { salsesDocumentsService } from "../../services/sales-documents.service";
import { SalesDocument } from "../models/sales-documents/sales-document.model";
import { CreateUpdateSalesDocument } from "../models/sales-documents/create-update-sales-document.model";

export function useSalesDocumentQuery(id: number | undefined) {
  return useQuery<SalesDocument>(
    ["sales-documents", id],
    () => salsesDocumentsService.getSalesDocument(id!),
    {
      enabled: !!id,
    }
  );
}

export function useCommunitySalesDocumentsQuery(
  communityId: number | undefined,
  from: Date | undefined,
  to: Date | undefined
) {
  return useQuery<SalesDocument[]>(
    ["sales-documents", "community", communityId, from, to],
    () =>
      salsesDocumentsService.getCommunitySalesDocuments(communityId!, from, to),
    {
      enabled: !!communityId,
    }
  );
}

export function useCreateSalesDocumentsMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    (model: CreateUpdateSalesDocument) =>
      salsesDocumentsService.createSalesDocument(model),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sales-documents");
      },
    }
  );
}

export function useUpdateSalesDocumentsMutation(id: number | undefined) {
  const queryClient = useQueryClient();

  return useMutation(
    (model: CreateUpdateSalesDocument) => {
      if (id === undefined || id === null) {
        throw new Error("Can not mutate sales document without id provided");
      }
      return salsesDocumentsService.updateSalesDocument(id, model);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sales-documents");
      },
    }
  );
}

export function useDeleteSalesDocumentsMutation(id: number | undefined) {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      if (id === undefined || id === null) {
        throw new Error("Can not mutate sales document without id provided");
      }
      return salsesDocumentsService.deleteSalesDocument(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sales-documents");
      },
    }
  );
}
