import { CommunityForSelector } from "../queries/models/communities/community-for-selector";
import { BaseService } from "./base.service";

class CommunitiesForSelectorService extends BaseService {
  async getCommunitiesForSelector(): Promise<CommunityForSelector[]> {
    const url = `${this.endpointPath}`;
    return this.http.get<CommunityForSelector[]>(url).then((res) => res.data);
  }
}

export const communitiesForSelectorService = new CommunitiesForSelectorService(
  "data-access/communities"
);
