import { CoinsProcessRequest } from "../queries/models/coins-processing/coins-process-request.model";
import { BaseService } from "./base.service";

class CoinsProcessingService extends BaseService {
  async process(data: CoinsProcessRequest) {
    const url = `${this.endpointPath}/process`;
    return this.http.post(url, data);
  }
}

export const coinsProcessingService = new CoinsProcessingService(
  "coins-processing"
);
