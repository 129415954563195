import { Dialog } from "primereact/dialog";
import { useToast } from "../ui/ToastContext";
import { useCallback } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useQueryClient } from "react-query";
import { useCreateCommunityMutation } from "../../queries/community-administration/community-administration.query";
import { CreateUpdateCommunity } from "../../queries/models/community-administration/create-update-community.model";
import { CreateCommunityForm } from "./CreateCommunityForm";

export interface CreateCommunityDialogProps {
  visible: boolean;
  onHide: () => void;
}

export function CreateCommunityDialog({
  visible,
  onHide,
}: CreateCommunityDialogProps) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const createCommunityMutation = useCreateCommunityMutation();

  const onSave = useCallback(
    (model: CreateUpdateCommunity) => {
      if (model) {
        const mutateOptions = {
          onSuccess: async () => {
            await queryClient.invalidateQueries("communities");
            onHide();
            toast.current?.show({
              severity: "success",
              detail: "Community saved",
            });
          },
          onError: async (error: any) => {
            toast.current?.show({
              severity: "error",
              detail: error.response?.data ?? error.message,
            });
          },
        };

        createCommunityMutation.mutateAsync(model, mutateOptions);
      }
    },
    [createCommunityMutation, onHide, queryClient, toast]
  );

  return (
    <>
      <Dialog
        className="max-w-full"
        visible={visible}
        header="Create community"
        onHide={onHide}
      >
        <div className="flex flex-column gap-2">
          <CreateCommunityForm
            onSave={onSave}
            onCancel={onHide}
          />
        </div>
      </Dialog>
      <ConfirmDialog />
    </>
  );
}
