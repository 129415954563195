import { Button } from "primereact/button";
import { CoinsBalance } from "../components/dashboard/CoinsBalance";
import { RoundedCard } from "../components/ui/RoundedCard";
import { CoinsTransactions } from "../components/wallet/CoinsTransactions";
import { useEnergyCommunitySelector } from "../components/ui/EnergyCommunityContext";
import { useCoinsBalanceQuery } from "../queries/wallet/wallet.query";

export function Wallet() {
  const { selectedCommunityId } = useEnergyCommunitySelector();
  const { data } = useCoinsBalanceQuery(selectedCommunityId);

  return (
    <div className="flex flex-col">
      <RoundedCard header="Energy coins balance">
        {data && (
          <CoinsBalance
            coins={data.coins}
            eur={data.eur}
          />
        )}
        <Button
          label="Transfer coins"
          className="mt-3"
        />
      </RoundedCard>
      <RoundedCard header="Energy coins changes">
        <CoinsTransactions communityId={selectedCommunityId} />
      </RoundedCard>
    </div>
  );
}
