import { memo } from "react";
import { RoundedCard } from "../components/ui/RoundedCard";
import { SalesDocumentsDetails } from "../components/sales-documents/SalesDocumentsDetails";

export const SalesDocumentsAdministration = memo(() => {
  return (
    <>
      <RoundedCard header="Sales documents">
        <SalesDocumentsDetails />
      </RoundedCard>
    </>
  );
});
