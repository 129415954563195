import { SelectItem } from "primereact/selectitem";
import { Member } from "../communities/member.model";
import { EnergyMeter } from "../community-administration/energy-meter.model";

export enum SalesDocumentType {
  Consumption = 1,
  Production = 2,
  EvChargingConsumption = 3,
  EvChargingProduction = 4,
  CoinsInjection = 5,
}

export interface SalesDocument {
  id: number;
  from: Date;
  to: Date;
  number: string;
  amountEur: number;
  amountKwh: number;
  memberId: number;
  meterEan: string;
  type: SalesDocumentType;
  debtorMemberId?: number;
  // creditorMemberId?: number;
  isProcessed: boolean;
  processedAt?: Date;

  energyMeter: EnergyMeter;
  debtor?: Member;
  //creditor?: Member;
}

export const salesDocumentTypeOptions = Object.entries(SalesDocumentType)
  .filter((x) => typeof x[1] === "number")
  .map(
    (x) =>
      ({
        label: x[0],
        value: x[1],
      } as SelectItem)
  );
