import { AxiosError } from "axios";
import { LoginResponse } from "../queries/models/auth/login-response.model";
import { BaseService } from "./base.service";

class AuthService extends BaseService {
  async login(email: string, password: string) {
    try {
      const response = await this.http
        .post<LoginResponse>(this.endpointPath + "/login", {
          username: email,
          password,
        })
        .then((res) => res.data);

      console.info("Auth response", response);
      return response;
    } catch (error) {
      if (
        (error as AxiosError).response &&
        (error as AxiosError).response?.status === 401
      ) {
        throw new Error("Wrong username or password");
      }
      throw error;
    }
  }
}

export const authService = new AuthService("security/auth");
