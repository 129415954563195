import { useMutation, useQuery } from "react-query";
import { Member } from "../models/communities/member.model";
import { CreateUpdateEnergyMeter } from "../models/community-administration/create-update-energy-meter.model";
import { energyMeterAdministrationService } from "../../services/energy-meter-administration.service";

export function useCommunityEnergyMetersQuery(communityId: number | undefined) {
  return useQuery(
    ["energy-meters", "community", communityId],
    () =>
      energyMeterAdministrationService.getCommunityEnergyMeters(communityId!),
    {
      enabled: !!communityId,
    }
  );
}

export function useEnergyMeterQuery(ean: string | undefined) {
  return useQuery<Member>(
    ["energy-meters", ean],
    () => energyMeterAdministrationService.getEnergyMeter(ean!),
    {
      enabled: !!ean,
    }
  );
}

export function useCreateEnergyMeterMutation() {
  return useMutation((request: CreateUpdateEnergyMeter) =>
    energyMeterAdministrationService.createEnergyMeter(request)
  );
}

export function useUpdateEnergyMeterMutation() {
  return useMutation((request: CreateUpdateEnergyMeter) =>
    energyMeterAdministrationService.updateEnergyMeter(request.ean!, request)
  );
}

export function useDeleteEnergyMeterMutation() {
  return useMutation((ean: string) =>
    energyMeterAdministrationService.deleteEnergyMeter(ean)
  );
}
