import { UseQueryResult } from "react-query";
import { DataLoader } from "../ui/DataLoader";
import { formatNumber } from "../../utils/number-utils";

interface EnergyGeneratedProps {
  energy: number;
  query: UseQueryResult;
}

export function EnergyGenerated({ energy, query }: EnergyGeneratedProps) {
  return (
    <div className="flex flex-col items-center justify-center">
      <div>
        <img
          className="w-24 p-1"
          src="/images/bolt.png"
          alt="img"
        />
      </div>
      <DataLoader
        query={query}
        height="1.5rem"
        width="70%"
        loaderClassName="mt-2"
        className="text-3xl font-bold text-center text-slate-700"
      >
        {formatNumber(energy / 1000, 0, 0)} kWh
      </DataLoader>
    </div>
  );
}
