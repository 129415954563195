import { Dialog } from "primereact/dialog";
import { useToast } from "../ui/ToastContext";
import { useCallback } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useQueryClient } from "react-query";
import { CreateUpdateCommunityMember } from "../../queries/models/community-administration/create-update-community-member.model";
import { useCreateMemberMutation } from "../../queries/member-administration/member-administration.query";
import { CreateMemberForm } from "./CreateMemberForm";

export interface CreateMemberDialogProps {
  visible: boolean;
  communityId: number;
  onHide: () => void;
}

export function CreateMemberDialog({
  visible,
  communityId,
  onHide,
}: CreateMemberDialogProps) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const createMemberMutation = useCreateMemberMutation();

  const onSave = useCallback(
    (model: CreateUpdateCommunityMember) => {
      if (model) {
        const mutateOptions = {
          onSuccess: async () => {
            await queryClient.invalidateQueries("communities");
            onHide();
            toast.current?.show({
              severity: "success",
              detail: "Community member added",
            });
          },
          onError: async (error: any) => {
            toast.current?.show({
              severity: "error",
              detail: error.response?.data ?? error.message,
            });
          },
        };

        createMemberMutation.mutateAsync(model, mutateOptions);
      }
    },
    [createMemberMutation, onHide, queryClient, toast]
  );

  return (
    <>
      <Dialog
        className="max-w-full"
        visible={visible}
        header="Create community"
        onHide={onHide}
      >
        <div className="flex flex-column gap-2">
          <CreateMemberForm
            onSave={onSave}
            onCancel={onHide}
            communityId={communityId}
          />
        </div>
      </Dialog>
      <ConfirmDialog />
    </>
  );
}
