import { RoundedCard } from "../components/ui/RoundedCard";
import { useEnergyCommunitySelector } from "../components/ui/EnergyCommunityContext";
import { CommunityDetails } from "../components/community/CommunityDetails";

export function CommunityAdministration() {
  const { selectedCommunityId } = useEnergyCommunitySelector();

  return (
    <>
      <RoundedCard header="Details">
        <CommunityDetails communityId={Number(selectedCommunityId)} />
      </RoundedCard>
    </>
  );
}
