import {
  faSun,
  faWind,
  faChargingStation,
  faSeedling,
  faUsers,
  faPieChart,
} from "@fortawesome/free-solid-svg-icons";
import { EnergyCommunityItem } from "./EnergyCommunityItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatNumber } from "../../utils/number-utils";
import { DataLoader } from "../ui/DataLoader";
import { UseQueryResult } from "react-query";

interface MyEnergyCommunityProps {
  peakSolarPower?: number;
  peakWindPower?: number;
  chargingStations?: number;
  greenEnergyProduced?: number;
  members?: number;
  share?: number;
  query?: UseQueryResult;
}

export function MyEnergyCommunity({
  peakSolarPower,
  peakWindPower,
  chargingStations,
  greenEnergyProduced,
  members,
  share,
  query,
}: MyEnergyCommunityProps) {
  return (
    <div>
      <div className="flex flex-col">
        <EnergyCommunityItem
          icon={faSun}
          text={formatNumber(peakSolarPower) + " W peak solar power"}
          query={query}
        />
        {!!peakWindPower && (
          <EnergyCommunityItem
            icon={faWind}
            text={formatNumber(peakWindPower) + " W peak wind power"}
            query={query}
          />
        )}
        <EnergyCommunityItem
          icon={faChargingStation}
          text={formatNumber(chargingStations) + " EV charging stations"}
          query={query}
        />
        <EnergyCommunityItem
          icon={faSeedling}
          text={
            formatNumber((greenEnergyProduced ?? 0) / 1000) +
            " kWh green energy produced"
          }
          query={query}
        />
        <EnergyCommunityItem
          icon={faUsers}
          text={formatNumber(members) + " members"}
          query={query}
        />
        <div className="mt-3 flex flex-col items-center">
          <FontAwesomeIcon
            icon={faPieChart}
            className="text-blue-500 text-6xl mr-2"
          />
          <div className="text-center text-slate-700">Your share</div>
          <DataLoader
            query={query}
            className="text-center text-slate-700 font-bold text-3xl"
            width="50%"
            height="2rem"
          >
            {formatNumber((share ?? 0) * 100, 4)}%
          </DataLoader>
        </div>
      </div>
    </div>
  );
}
