import { memo, useCallback, useMemo } from "react";
import { Dialog } from "primereact/dialog";
import { CreateUpdateSalesDocument } from "../../queries/models/sales-documents/create-update-sales-document.model";
import { useToast } from "../ui/ToastContext";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInputNumber from "../formik/FormikInputNumber";
import FormikCalendar from "../formik/FormikCalendar";
import { FormToolbar } from "../ui/form-toolbar";
import FormikInputText from "../formik/FormikInputText";
import FormikDropDown from "../formik/FormikDropdown";
import { SelectItem } from "primereact/selectitem";
import { useCommunityEnergyMetersQuery } from "../../queries/energy-meter-administraion/energy-meter-administration.query";
import { useCommunityMembersQuery } from "../../queries/member-administration/member-administration.query";
import {
  useCreateSalesDocumentsMutation,
  useDeleteSalesDocumentsMutation,
  useUpdateSalesDocumentsMutation,
} from "../../queries/sales-documents/sales-documents.query";
import {
  SalesDocument,
  SalesDocumentType,
  salesDocumentTypeOptions,
} from "../../queries/models/sales-documents/sales-document.model";
import { useEnergyCommunitySelector } from "../ui/EnergyCommunityContext";
import { addMonths, startOfMonth } from "date-fns";

export const SalesDocumentFormDialog = memo(
  ({
    salesDocument,
    onHide,
  }: {
    salesDocument: Partial<SalesDocument> | undefined;
    onHide: () => void;
  }) => {
    const { selectedCommunityId } = useEnergyCommunitySelector();

    const energyMetersQuery =
      useCommunityEnergyMetersQuery(selectedCommunityId);
    const membersQuery = useCommunityMembersQuery(selectedCommunityId);

    const energyMetersEanOptions = useMemo(() => {
      if (!energyMetersQuery.data) return [] as SelectItem[];
      return energyMetersQuery.data.map(
        (x) =>
          ({
            label: x.name,
            value: x.ean,
          } as SelectItem)
      );
    }, [energyMetersQuery.data]);

    const membersOptions = useMemo(() => {
      if (!membersQuery.data) return [] as SelectItem[];
      return membersQuery.data.map(
        (x) =>
          ({
            label: `${x.name} (id: ${x.id})`,
            value: x.id,
          } as SelectItem)
      );
    }, [membersQuery.data]);

    const initialValues: CreateUpdateSalesDocument = {
      meterEan: salesDocument?.meterEan ?? null,
      memberId: salesDocument?.memberId ?? 0,
      amountEur: salesDocument?.amountEur ?? 0,
      amountKwh: salesDocument?.amountKwh ?? 0,
      from: salesDocument?.from ?? startOfMonth(new Date()),
      to: salesDocument?.to ?? addMonths(startOfMonth(new Date()), 1),
      number: salesDocument?.number ?? "",
      // creditorMemberId: salesDocument?.creditorMemberId,
      debtorMemberId: salesDocument?.debtorMemberId,
      type: salesDocument?.type ?? SalesDocumentType.Consumption,
    };
    const toast = useToast();
    const validationSchema = Yup.object({
      meterEan: Yup.string(),
      memberId: Yup.number().required("Required"),
      amountEur: Yup.number().required("Required"),
      amountKwh: Yup.number().required("Required"),
      from: Yup.date().required("Required"),
      to: Yup.date().required("Required"),
      number: Yup.string().required("Required"),
      //  creditorMemberId: Yup.number().optional().nullable(),
      debtorMemberId: Yup.number().optional().nullable(),
      type: Yup.number().required("Required"),
    });

    const createSalesDocumentMutation = useCreateSalesDocumentsMutation();
    const updateSalesDocumentMutation = useUpdateSalesDocumentsMutation(
      salesDocument?.id
    );
    const deleteSalesDocumentMutation = useDeleteSalesDocumentsMutation(
      salesDocument?.id
    );

    const onSubmit = useCallback(
      (values: CreateUpdateSalesDocument) => {
        const operation = salesDocument?.id
          ? updateSalesDocumentMutation
          : createSalesDocumentMutation;

        operation.mutateAsync(values).then(
          () => {
            toast.current?.show({
              content: "Saved successfully",
              severity: "success",
            });
            onHide();
          },
          () => {
            toast.current?.show({
              content: "Saving failed",
              severity: "error",
            });
          }
        );
      },
      [
        createSalesDocumentMutation,
        onHide,
        salesDocument?.id,
        toast,
        updateSalesDocumentMutation,
      ]
    );

    const onDelete = useCallback(() => {
      deleteSalesDocumentMutation.mutateAsync().then(
        () => {
          toast.current?.show({
            content: "Deleted successfully",
            severity: "success",
          });
          onHide();
        },
        () => {
          toast.current?.show({
            content: "Deletion failed",
            severity: "error",
          });
        }
      );
    }, [deleteSalesDocumentMutation, onHide, toast]);

    return (
      <Dialog
        visible={!!salesDocument}
        onHide={onHide}
        header="Sales document"
      >
        <div className="h-full overflow-auto">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
            validateOnChange
            validateOnMount
          >
            {(formik) => (
              <Form className="flex h-full flex-col">
                <div className="formgrid grid mb-4 p-1">
                  <div className="field col-12 md:col-6">
                    <FormikInputText
                      validationSchema={validationSchema}
                      label="Number"
                      name="number"
                      isIndependent
                    />
                  </div>
                </div>
                <div className="formgrid grid mb-4 p-1">
                  <div className="field col-12 md:col-6">
                    <FormikDropDown
                      validationSchema={validationSchema}
                      label="Type"
                      name="type"
                      options={salesDocumentTypeOptions}
                      isIndependent
                      onChange={(e) => {
                        formik.setValues((prev) => {
                          return {
                            ...prev,
                            type: e.value,
                            debtorMemberId: [
                              SalesDocumentType.EvChargingProduction,
                              SalesDocumentType.EvChargingConsumption,
                            ].includes(e.value)
                              ? prev.debtorMemberId
                              : undefined,
                          };
                        }, true);
                      }}
                    />
                  </div>
                </div>
                <div className="formgrid grid mb-4 p-1">
                  <div className="field col-12 md:col-6">
                    <FormikInputNumber
                      validationSchema={validationSchema}
                      label="Eur"
                      name="amountEur"
                      isIndependent
                    />
                  </div>
                </div>
                <div className="formgrid grid mb-4 p-1">
                  <div className="field col-12 md:col-6">
                    <FormikInputNumber
                      validationSchema={validationSchema}
                      label="Kwh"
                      name="amountKwh"
                      isIndependent
                    />
                  </div>
                </div>
                <div className="formgrid grid mb-4 p-1">
                  <div className="field col-12 md:col-6">
                    <FormikCalendar
                      validationSchema={validationSchema}
                      label="From"
                      name="from"
                      isIndependent
                      showTime
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <FormikCalendar
                      validationSchema={validationSchema}
                      label="To"
                      name="to"
                      isIndependent
                      showTime
                    />
                  </div>
                </div>
                <div className="formgrid grid mb-4 p-1">
                  <div className="field col-12 md:col-6">
                    <FormikDropDown
                      validationSchema={validationSchema}
                      label="Member"
                      name="memberId"
                      isIndependent
                      options={membersOptions}
                    />
                  </div>
                </div>

                <div className="formgrid grid mb-4 p-1">
                  <div className="field col-12 md:col-6">
                    <FormikDropDown
                      validationSchema={validationSchema}
                      label="Meter EAN"
                      name="meterEan"
                      isIndependent
                      options={energyMetersEanOptions}
                      showClear
                    />
                  </div>
                </div>

                <div className="formgrid grid mb-4 p-1">
                  {/*  
                  <div className="field col-12 md:col-6">
                    <FormikDropDown
                      validationSchema={validationSchema}
                      label="Creditor"
                      name="creditorMemberId"
                      isIndependent
                      options={membersOptions}
                      showClear
                    />
                  </div>
                */}
                  {
                    <div className="field col-12 md:col-6">
                      <FormikDropDown
                        validationSchema={validationSchema}
                        label="Debtor"
                        name="debtorMemberId"
                        options={membersOptions}
                        showClear
                        disabled={
                          ![
                            SalesDocumentType.EvChargingProduction,
                            SalesDocumentType.EvChargingConsumption,
                          ].includes(formik.values["type"])
                        }
                        placeholder="None"
                      />
                    </div>
                  }
                </div>

                <FormToolbar
                  saveButtonDisabled={!formik.dirty || !formik.isValid}
                  showDeleteButton={!!salesDocument?.id}
                  onDeleteClick={onDelete}
                  onSaveClick={async () => {
                    if (!formik.isValid) {
                      toast.current?.show({
                        severity: "error",
                        detail: "Form invalid",
                      });
                      return;
                    }
                    return formik.submitForm();
                  }}
                  onCancelClick={onHide}
                />
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>
    );
  }
);
