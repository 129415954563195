import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { SelectItem } from "primereact/selectitem";
import { useMemo, useState } from "react";
import { Language } from "../../queries/models/translations/language.model";
import {
  useLanguagesQuery,
  usePublishLanguageMutation,
  useSelectLanguageMutation,
  useUnpublishLanguageMutation,
  useUnselectLanguageMutation,
} from "../../queries/translations/translations.query";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { classNames } from "primereact/utils";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { ShowFor } from "../ui/ShowFor";
import { usePermissions } from "../../hooks/use-permissions";
import { Permissions } from "../../utils/permissions";

export interface LanguagesEditorProps {
  onLanguageSelect(language?: Language): any;
}

export function LanguagesEditor({ onLanguageSelect }: LanguagesEditorProps) {
  const { t } = useTranslation();
  const languagesQuery = useLanguagesQuery();
  const selectMutator = useSelectLanguageMutation();
  const unselectMutator = useUnselectLanguageMutation();
  const publishMutator = usePublishLanguageMutation();
  const unpublishMutator = useUnpublishLanguageMutation();

  const { hasPermission } = usePermissions();

  const queryClient = useQueryClient();

  const [addingLanguageId, setAddingLanguageId] = useState<number>();
  const [selectedLanguage, setSelectedLanguage] = useState<Language>();

  const allLanguages = useMemo(
    () =>
      languagesQuery.data?.map(
        (x) =>
          ({
            label: x.name,
            value: x.id,
            disabled: x.isPublished || x.isSelected,
          } as SelectItem)
      ),
    [languagesQuery.data]
  );

  const activeLanguages = useMemo(
    () => languagesQuery.data?.filter((x) => x.isSelected),
    [languagesQuery.data]
  );

  function actionColumnBody(language: Language) {
    if (!hasPermission(Permissions.ModifyTranslations)) return <></>;

    if (language.isPublished) {
      return (
        <div>
          <Button
            label={t("translations.unpublish")}
            className="p-button-warning"
            onClick={() => {
              confirmDialog({
                message: t("translations.confirmUnpublishLanguage"),
                header: t("common.confirmation"),
                icon: "pi pi-exclamation-triangle text-yellow-600",
                accept: () => {
                  unpublishMutator
                    .mutateAsync(language.id)
                    .then(() => queryClient.invalidateQueries("languages"));
                },
              });
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="lg:flex">
          <Button
            label={t("translations.publish")}
            onClick={() => {
              confirmDialog({
                message: t("translations.confirmPublishLanguage"),
                header: t("common.confirmation"),
                icon: "pi pi-question-circle text-blue-600",
                accept: () => {
                  publishMutator
                    .mutateAsync(language.id)
                    .then(() => queryClient.invalidateQueries("languages"));
                },
              });
            }}
          />
          <Button
            label={t("translations.disactive")}
            className="p-button-danger"
            onClick={() => {
              confirmDialog({
                message: t("translations.confirmDeactivateLanguage"),
                header: t("common.confirmation"),
                icon: "pi pi-exclamation-circle text-red-600",
                accept: () => {
                  unselectMutator
                    .mutateAsync(language.id)
                    .then(() => queryClient.invalidateQueries("languages"));
                },
              });
            }}
          />
        </div>
      );
    }
  }

  return (
    <div
      className={classNames(
        "transition duration-150",
        languagesQuery.isFetching && "blur-sm"
      )}
    >
      <ShowFor permission={Permissions.ModifyTranslations}>
        <div className="flex py-2">
          <Dropdown
            options={allLanguages}
            placeholder={t("translations.addNewLanguage")}
            scrollHeight="50vh"
            filter
            value={addingLanguageId}
            onChange={(e) => setAddingLanguageId(e.value)}
            virtualScrollerOptions={{ itemSize: 50 }}
          />
          <Button
            className="min-w-[6rem]"
            label={t("common.add")}
            onClick={() => {
              if (!addingLanguageId) return;
              selectMutator
                .mutateAsync(addingLanguageId)
                .then(() => queryClient.invalidateQueries("languages"));
            }}
            icon="pi pi-plus"
          />
        </div>
      </ShowFor>
      <DataTable
        value={activeLanguages}
        selectionMode="single"
        selection={selectedLanguage}
        onSelectionChange={(e) => {
          setSelectedLanguage(e.value as Language | undefined);
          onLanguageSelect(e.value as Language | undefined);
        }}
        dataKey="id"
        size="small"
        scrollable
        scrollHeight="230px"
      >
        <Column
          header={t("translations.language")}
          field="name"
        />
        <Column
          header={t("common.state")}
          body={(language: Language) =>
            language.isPublished
              ? t("translations.published")
              : t("translations.unpublished")
          }
        />
        <Column
          header={t("common.action")}
          body={actionColumnBody}
        />
      </DataTable>

      <ConfirmDialog />
    </div>
  );
}
