import { useQuery } from "react-query";
import { CommunityDetails } from "../models/communities/community-details.model";
import { communitiesService } from "../../services/communities.service";
import { CommunityForSelector } from "../models/communities/community-for-selector";
import { communitiesForSelectorService } from "../../services/communities-for-selector.service";

export function useCommunitiesQuery() {
  return useQuery<CommunityDetails[]>("communities", () =>
    communitiesService.getCommunities()
  );
}
export function useCommunityQuery(id: number | undefined) {
  return useQuery<CommunityDetails>(
    ["communities", id],
    () => communitiesService.getCommunity(id!),
    {
      enabled: !!id,
    }
  );
}

export function useCommunitiesForSelectorQuery() {
  return useQuery<CommunityForSelector[]>("communitiesForSelector", () =>
    communitiesForSelectorService.getCommunitiesForSelector()
  );
}
