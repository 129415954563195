import { endOfMonth, format, startOfMonth } from "date-fns";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { useChargingSessionsQuery } from "../../queries/ev/charging-sessions.query";
import { ChargingSession } from "../../queries/models/ev/charging-session";
import { useEnergyCommunitySelector } from "../ui/EnergyCommunityContext";
import { formatNumber } from "../../utils/number-utils";

export function ChargingSessions() {
  const [from, setFrom] = useState<Date>(startOfMonth(new Date()));
  const [to, setTo] = useState<Date>(endOfMonth(new Date()));

  const { selectedCommunityId } = useEnergyCommunitySelector();

  const chargingSessionsQuery = useChargingSessionsQuery(
    selectedCommunityId,
    from,
    to
  );

  const data = chargingSessionsQuery.data;

  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <Calendar
          id="calendar"
          value={from}
          onChange={(e) => {
            setFrom(e.value as Date);
            setTo(endOfMonth(e.value as Date));
          }}
          dateFormat="MM yy"
          view="month"
          className="mb-3 p-inputtext-centered"
        />
      </div>

      <DataTable value={data}>
        <Column
          header="Start date"
          body={(rowData: ChargingSession) => {
            return <div>{format(rowData.startDate, "dd.MM.yyyy HH:mm")}</div>;
          }}
        />
        <Column
          header="End date"
          body={(rowData: ChargingSession) => {
            return <div>{format(rowData.endDate, "dd.MM.yyyy HH:mm")}</div>;
          }}
        />
        <Column
          field="energyDelivered"
          header="Energy delivered"
          body={(rowData) => (
            <span>{formatNumber(rowData.energyDelivered, 2)} kWh</span>
          )}
        />
        <Column
          field="coinsUsed"
          header="Coins used"
          body={(rowData) => <span>{formatNumber(rowData.coinsUsed, 2)}</span>}
        />
        <Column
          field="costInEur"
          header="Cost"
          body={(rowData) => (
            <span>{formatNumber(rowData.costInEur, 2)} €</span>
          )}
        />
      </DataTable>
    </div>
  );
}
