import { ShowFor } from "../ui/ShowFor";
import { SideBarElementMobile } from "./side-bar-element-mobile";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { Sidebar } from "primereact/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "../../hooks/use-window-size";
import { useEffect, useState } from "react";
import { MenuElement } from "./side-bar-nav";
import { useTranslation } from "react-i18next";
import { LanguageDropdown } from "./language-dropdown";

const pixelsPerItem = 125;

export function SideBarElementsMobile({
  menuElements,
}: {
  menuElements: MenuElement[];
}) {
  const { t } = useTranslation();
  const [visibleRight, setVisibleRight] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (visibleRight) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [visibleRight]);

  return (
    <>
      <div></div>
      <ul className="list-none px-3 flex justify-between">
        {menuElements
          .filter((_, i) => pixelsPerItem * (i + 1) < windowSize.width)
          .map((x, i) => (
            <ShowFor
              key={i}
              permission={x.permission}
            >
              <SideBarElementMobile
                to={x.to}
                label={t(x.label)}
                icon={x.icon}
              />
            </ShowFor>
          ))}
        {windowSize.width < pixelsPerItem * menuElements.length && (
          <li
            onClick={() => setVisibleRight(true)}
            className="grow basis-0"
          >
            <div className="text-center flex flex-col items-center no-underline text-sm">
              <FontAwesomeIcon
                icon={faEllipsis}
                className="text-xl text-gray-600"
              />
              <div className="text-gray-600 text-sm">{t("common.more")}</div>
            </div>
          </li>
        )}
      </ul>
      <div className="card">
        <Sidebar
          className="!w-40"
          ariaCloseLabel="true"
          visible={visibleRight}
          position="right"
          onHide={() => setVisibleRight(false)}
        >
          <div className="flex justify-between flex-col h-full">
            <ul>
              {menuElements
                .filter((_, i) => pixelsPerItem * (i + 1) >= windowSize.width)
                .map((x, i) => (
                  <div
                    key={i}
                    onClick={() => setVisibleRight(false)}
                    className="py-3"
                  >
                    <ShowFor permission={x.permission}>
                      <SideBarElementMobile
                        to={x.to}
                        label={t(x.label)}
                        icon={x.icon}
                      />
                    </ShowFor>
                  </div>
                ))}
            </ul>
            {!windowSize.sm && <LanguageDropdown />}
          </div>
        </Sidebar>
      </div>
    </>
  );
}
