import "./App.css";
import { locale, addLocale, PrimeReactProvider } from "primereact/api";
import { ToastContextProvider } from "./components/ui/ToastContext";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { AuthProvider } from "./auth/AuthProvider";
import { MainApp } from "./MainApp";
import { Route, Routes } from "react-router-dom";
import { Login } from "./pages/Login";
import { useTranslation } from "react-i18next";
import { useCultureTranslationsJsonQuery } from "./queries/translations/translations.query";

function App() {
  const toast = useRef<Toast>(null);

  addLocale("nl", {
    firstDayOfWeek: 1,
    dateFormat: "dd.mm.yy",
  });

  locale("nl");

  const { i18n } = useTranslation();

  const [rerenderer, setRerenderer] = useState(i18n.language);
  const languageQuery = useCultureTranslationsJsonQuery(i18n.language);

  useEffect(() => {
    if (!languageQuery.isLoading && languageQuery.isFetched && i18n.language) {
      try {
        i18n.addResourceBundle(
          i18n.language,
          "translation",
          JSON.parse(languageQuery.data ?? "")
        );
        setRerenderer(i18n.language);
      } catch {
        console.warn("Failed to load translations");
      }
    }
  }, [
    rerenderer,
    i18n,
    languageQuery,
    i18n.language,
    languageQuery.isFetched,
    languageQuery.isLoading,
  ]);

  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <Toast ref={toast} />
      <ToastContextProvider toastRef={toast}>
        <PrimeReactProvider>
          <AuthProvider>
            <Routes>
              <Route
                path="login"
                element={<Login />}
              />
              <Route
                path="*"
                element={<MainApp />}
              />
            </Routes>
          </AuthProvider>
        </PrimeReactProvider>
      </ToastContextProvider>
    </>
  );
}

export default App;
