import { useMutation, useQueryClient } from "react-query";
import { CoinsProcessRequest } from "../models/coins-processing/coins-process-request.model";
import { coinsProcessingService } from "../../services/coins.processing.service";

export function useCoinsProcessingMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    (model: CoinsProcessRequest) => coinsProcessingService.process(model),
    {
      onSettled: (_1, _2, model) => {
        queryClient.invalidateQueries([
          "sales-documents",
          "community",
          model.communityId,
        ]);
      },
    }
  );
}
