import { useCallback, useState } from "react";
import { Button } from "primereact/button";
import { useWindowSize } from "../../hooks/use-window-size";
import { classNames } from "primereact/utils";

export interface EditContentHeaderProps {
  showDeleteButton: boolean;
  saveButtonDisabled: boolean;
  extraBtn1?: React.ReactNode;
  onSaveClick: () => Promise<any> | void;
  onCancelClick: () => Promise<any> | void;
  onDeleteClick?: () => Promise<any> | void;
}

export function FormToolbar({
  showDeleteButton,
  saveButtonDisabled,
  extraBtn1,
  onSaveClick,
  onCancelClick,
  onDeleteClick,
}: EditContentHeaderProps) {
  const { lg } = useWindowSize();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSave = useCallback(() => {
    setIsSubmitting(true);
    Promise.resolve(onSaveClick()).finally(() => setIsSubmitting(false));
  }, [onSaveClick]);

  const onCancel = useCallback(() => {
    setIsSubmitting(true);
    Promise.resolve(onCancelClick()).finally(() => setIsSubmitting(false));
  }, [onCancelClick]);

  const onDelete = useCallback(() => {
    setIsSubmitting(true);
    if (onDeleteClick) {
      Promise.resolve(onDeleteClick()).finally(() => setIsSubmitting(false));
    }
  }, [onDeleteClick]);

  return (
    <div className="flex justify-end">
      <div
        className={classNames(
          "flex",
          !lg ? "w-full justify-content-between" : ""
        )}
      >
        {extraBtn1}
        <Button
          icon="pi pi-save"
          type="button"
          label="Save"
          loading={isSubmitting}
          className="min-w-max mr-1 w-full"
          onClick={onSave}
          disabled={saveButtonDisabled}
        />
        {showDeleteButton && (
          <Button
            icon="pi pi-trash"
            type="button"
            label="Delete"
            loading={isSubmitting}
            className="min-w-max mr-1 w-full"
            onClick={onDelete}
            severity="danger"
          />
        )}
        <Button
          icon="pi pi-times"
          type="button"
          label="Cancel"
          className="min-w-max mr-1"
          onClick={onCancel}
          severity="secondary"
        />
      </div>
    </div>
  );
}
