interface DetailsRowProps {
  label: string;
  value: string | number | undefined;
}

export function DetailsRow({ label, value }: DetailsRowProps) {
  return (
    <div className="flex flex-row text-xl py-1">
      <div className="text-slate-600 min-w-64">{label}</div>
      <div className="ml-2"> {value}</div>
    </div>
  );
}
