import { Car } from "../queries/models/ev/car.model";
import { BaseService } from "./base.service";

class CarsService extends BaseService {
  async getCars(communityId: number): Promise<Car[]> {
    const url = `${this.endpointPath}/${communityId}/cars`;

    return this.http.get<Car[]>(url).then((res) => res.data);
  }
}

export const carsService = new CarsService("data-access/ev");
