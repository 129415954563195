import * as Yup from "yup";
import { useCallback } from "react";
import { Form, Formik } from "formik";
import { FormToolbar } from "../ui/form-toolbar";
import { useToast } from "../ui/ToastContext";
import FormikInputText from "../formik/FormikInputText";
import { EnergyMeter } from "../../queries/models/community-administration/energy-meter.model";
import { CreateUpdateEnergyMeter } from "../../queries/models/community-administration/create-update-energy-meter.model";
import FormikInputSwitch from "../formik/FormikInputSwitch";
import { Member } from "../../queries/models/communities/member.model";

interface EnergyMeterFormProps {
  onSave: (model: CreateUpdateEnergyMeter) => void;
  onDelete: (model: EnergyMeter) => void;
  onCancel: () => void;
  subject: EnergyMeter | undefined;
  member: Member | undefined;
}

export function EnergyMeterForm({
  onSave,
  onDelete,
  onCancel,
  subject,
  member,
}: EnergyMeterFormProps) {
  const initialValues: CreateUpdateEnergyMeter = {
    ean: subject?.ean ?? "",
    name: subject?.name ?? "",
    isProducer: subject?.isProducer ?? false,
    isConsumer: subject?.isConsumer ?? true,
    isProductionCommunityOwned: subject?.isProductionCommunityOwned ?? false,
    memberId: member?.id ?? 0,
  };
  const toast = useToast();
  const validationSchema = Yup.object({
    ean: Yup.string().required("Required"),
    name: Yup.string().optional(),
    memberId: Yup.number().optional(),
    isProducer: Yup.boolean().required(),
    isConsumer: Yup.boolean().required(),
    isProductionCommunityOwned: Yup.boolean().required(),
  });

  const onSubmit = useCallback(
    (values: CreateUpdateEnergyMeter) => {
      return onSave(values);
    },
    [onSave]
  );

  return (
    <div className="h-full overflow-auto">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
        validateOnMount
      >
        {(formik) => (
          <Form className="flex h-full flex-col">
            <div className="formgrid grid mb-4 p-1">
              <div className="field col-12 md:col-6">
                <FormikInputText
                  validationSchema={validationSchema}
                  label="Ean"
                  name="ean"
                  isIndependent
                  disabled={false}
                />
              </div>
            </div>
            <div className="formgrid grid mb-4 p-1">
              <div className="field col-12 md:col-6">
                <FormikInputText
                  validationSchema={validationSchema}
                  label="Name"
                  name="name"
                  isIndependent
                  disabled={false}
                />
              </div>
            </div>
            <div className="formgrid grid mb-4 p-1">
              <div className="field col-12 md:col-6">
                <FormikInputSwitch
                  checked={formik.values.isProducer}
                  validationSchema={validationSchema}
                  label="Is producer"
                  name="isProducer"
                  isIndependent
                />
              </div>
            </div>
            <div className="formgrid grid mb-4 p-1">
              <div className="field col-12 md:col-6">
                <FormikInputSwitch
                  checked={formik.values.isProductionCommunityOwned}
                  validationSchema={validationSchema}
                  label="Production public"
                  name="isProductionCommunityOwned"
                  isIndependent
                />
              </div>
            </div>
            <div className="formgrid grid mb-4 p-1">
              <div className="field col-12 md:col-6">
                <FormikInputSwitch
                  checked={formik.values.isConsumer}
                  validationSchema={validationSchema}
                  label="Is consumer"
                  name="isConsumer"
                  isIndependent
                />
              </div>
            </div>

            <FormToolbar
              saveButtonDisabled={!formik.dirty || !formik.isValid}
              showDeleteButton={!!subject?.ean}
              onDeleteClick={() => onDelete(subject!)}
              onSaveClick={async () => {
                if (!formik.isValid) {
                  toast.current?.show({
                    severity: "error",
                    detail: "Form invalid",
                  });
                  return;
                }
                return formik.submitForm();
              }}
              onCancelClick={onCancel}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
